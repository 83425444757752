import Map from '../components/weatherMap';
import CommTabs from '../components/comm/commTabs';

export const utilities = [
  {
    tab: 'Comm Devices',
    items: [
      {
        title: 'Devices',
        description: 'Devices',
        component: <CommTabs />,
      },
      // {
      //   title: 'Hughes',
      //   description: 'Hughes Devices',
      //   component: <VentusDevice />,
      // },
    ],
  },
  {
    tab: 'Weather Map',
    items: [
      {
        title: 'Weather Map',
        description: 'Weather Map',
        component: <Map />,
      },
    ],
  },
];
