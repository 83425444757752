import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { utilities } from '../../utils/contactCenterCommDevices';

const CommTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label='Contact Center Tabs'
        indicatorColor='primary'
        textColor='primary'
        // sx={{ marginBottom: 2 }}
      >
        {utilities.map((utility, index) => (
          <Tab key={index} label={utility.tab} />
        ))}
      </Tabs>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
        }}
      >
        {utilities[selectedTab].items.map((utility, index) => (
          <Box key={index} sx={{ flexGrow: 1, marginBottom: 2 }}>
            {utility.component}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CommTabs;
