// Forms Menu Data
export const formMenuData = translations => [
  {
    country: translations.unitedStates || 'United States',
    teams: [
      {
        name: 'FST',
        forms: [
          {
            name: translations.incorrectCase || 'Incorrect Case',
            componentKey: 'IncorrectCaseForm',
          },
          {
            name: translations.overtime || 'Overtime',
            componentKey: 'FSTOvertimeForm',
          },
          {
            name: translations.fieldRepair || 'Field Repair',
            componentKey: 'FieldRepairForm',
          },
        ],
      },
      {
        name: 'NRC',
        forms: [{ name: 'Comm Device Audit', componentKey: 'CommDeviceAudit' }],
      },
      {
        name: 'RRF',
        forms: [
          {
            name: translations.lotteryRepairWorklog || 'Lottery Repair Worklog',
            componentKey: 'LotteryRepairWorklogForm',
          },
          {
            name: translations.gamingRepairWorklog || 'Gaming Repair Worklog',
            componentKey: 'GamingRepairWorklogForm',
          },
        ],
      },
    ],
  },
];

// Reporting Menu Data
export const reportMenuData = translations => [
  {
    country: translations.unitedStates || 'United States',
    teams: [
      {
        name: 'FST',
        reports: [
          {
            name: translations.incorrectCase || 'Incorrect Case',
            componentKey: 'IncorrectCaseReport',
          },
          {
            name: translations.overtime || 'Overtime',
            componentKey: 'FSTOvertimeReport',
          },
          {
            name: translations.fieldRepair || 'Field Repair',
            componentKey: 'FieldRepairReport',
          },
        ],
      },
      {
        name: 'NRC',
        reports: [{}],
      },
      {
        name: 'RRF',
        reports: [
          {
            name: translations.lotteryRepairWorklog || 'Lottery Repair Worklog',
            componentKey: 'LotteryRepairWorklogReport',
          },
          {
            name: translations.gamingRepairWorklog || 'Gaming Repair Worklog',
            componentKey: 'GamingRepairWorklogReport',
          },
        ],
      },
    ],
  },
];

// Files Menu Data
export const filesMenuData = translations => [
  {
    country: translations.unitedStates || 'United States',
    teams: [
      {
        name: translations.contactCenter || 'Contact Center',
        files: [
          {
            name: translations.retailerFiles || 'Retailer Files',
            componentKey: 'RetailerFilesDataGrid',
          },
        ],
      },
      {
        name: 'FST',
        files: [
          {
            name: translations.fstFiles || 'FST Files',
            componentKey: 'FSTFilesDataGrid',
          },
        ],
      },
      {
        name: 'GCS',
        files: [
          {
            name: translations.gcsFiles || 'GCS Files',
            componentKey: 'GCSFilesDataGrid',
          },
        ],
      },
    ],
  },
  {
    country: translations.costaRica || 'Costa Rica',
    teams: [
      {
        name: translations.contactCenter || 'Contact Center',
        files: [
          {
            name: translations.contactCenterFiles || 'Contact Center Files',
            componentKey: 'CRCCFilesDataGrid',
          },
        ],
      },
    ],
  },
];
