import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import {
    Tooltip,
    LinearProgress,
    Snackbar,
    Typography,
    Button,
    IconButton,
    Box,
} from '@mui/material';
import Cookies from 'js-cookie';
import {
    ToggleOffIcon,
    ToggleOnIcon,
    FileUploadIcon,
    ContentCopyIcon,
    CloudDownloadIcon,
    DeleteForeverIcon,
    VisibilityIcon, 
    InfoIcon
} from '../assets/icons/icons';
import { formStyles } from '../styles/formStyles';
import EditableModal from './datagridEditableModal';
import { ActionCell } from '../utils/reportConfigs';
import FileInfoModal from './fileInformationModal';
import FilesUploadComponent from './filesModal';
import PreviewModal from './previewModal'; 
import { LanguageContext } from '../context/languageContext';

const DataGridComponent = ({
    apiEndpoint,
    columns,
    uploadButton,
    toggleIcons,
}) => {
    const [data, setData] = useState({ rows: [] });
    const [loading, setLoading] = useState(true);
    const [showIcons, setShowIcons] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [articleID, setArticleID] = useState('');
    const [userName, setUserName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [snackbarConfig, setSnackbarConfig] = useState({
        open: false,
        message: '',
        severity: 'success',
    });
    const [fileInfoOpen, setFileInfoOpen] = useState(false);
    const [fileInfo, setFileInfo] = useState(null);
    const translations = useContext(LanguageContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiEndpoint);
                setData({ rows: response.data || [] });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
        fetchUserDetails();
    }, [apiEndpoint]);

    const fetchUserDetails = async () => {
        const usersEndpoint = process.env.REACT_APP_ENDPOINT_QA_USERS;
        const token = Cookies.get('token');
        if (!token) {
            console.error('Access token is not available.');
            return;
        }

        try {
            const response = await axios.get(`${usersEndpoint}/user-details`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data) {
                setUserDetails(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch user details:', error);
            setUserDetails({});
        }
    };

    
    const handleShow = (id) => {
        setShow(true);
        setRowId(id);
        const selectedData = data.rows.find((row) => row.id === id);
        setSelectedRowData(selectedData);
    };
    
    const handleClose = () => {
        setShow(false);
        setSelectedRowData(null);
    };
    
    const editApiEndpoint = process.env.REACT_APP_ENDPOINT_QA_REPORTING
    
    const handleEdit = async (event) => {
        event.preventDefault();
        const modifiedBy = userDetails?.displayName || 'Unknown User';
        const modified = new Date().toISOString().slice(0, 19).replace('T', ' ');
        const dataToUpdate = {
            ...selectedRowData,
            modifiedBy,
            modified,
        };

        try {
            setLoading(true);
            await axios.put(`${editApiEndpoint}/fst/schedule/edit/${rowId}`, dataToUpdate);
            setLoading(false);
            setSnackbarConfig({
                open: true,
                message: 'Update successful. Page will refresh automatically.',
                severity: 'success',
            });
            setIsSubmitting(false);
            setTimeout(() => window.location.reload(), 3000);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => ({ ...prev, open: false }));
    };

    const handleUploadClick = () => {
        setOpen(true);
    };

    const copyFileLink = (row) => {
        const fileLink = row.link;
        const textArea = document.createElement('textarea');
        textArea.style.position = 'fixed';
        textArea.style.opacity = 0;
        textArea.value = fileLink;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setSnackbarConfig({
            open: true,
            message: 'File link copied to clipboard.',
            severity: 'success',
        });
    };

    const handleDownload = (row) => {
        const fileLink = row.link;
        window.open(fileLink, '_blank');
        setSnackbarConfig({
            open: true,
            message: 'File downloaded successfully.',
            severity: 'success',
        });
    };

    const handleDelete = async (row) => {
        const apiUrl = `${apiEndpoint}/${row.fileName}`;
        try {
            await axios.delete(apiUrl);
            const updatedData = data.rows.filter((f) => f.id !== row.id);
            setData({ rows: updatedData });
            setSnackbarConfig({
                open: true,
                message: 'File deleted successfully.',
                severity: 'success',
            });
        } catch (error) {
            setSnackbarConfig({
                open: true,
                message: 'Error deleting file. Please try again.',
                severity: 'error',
            });
        }
    };

    const handlePreview = (row) => {
        setPreviewFile(row.link);
        setPreviewOpen(true);
    };

    const handleSubmit = async () => {
        if (selectedFile && articleID && userName) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('articleID', articleID);
            formData.append('userName', userName);
            const apiUrl = `${apiEndpoint}/upload`;

            try {
                await axios.post(apiUrl, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                setOpen(false);
                setSnackbarConfig({
                    open: true,
                    message: 'File uploaded successfully.',
                    severity: 'success',
                });
            } catch (error) {
                setSnackbarConfig({
                    open: true,
                    message: 'Error uploading file. Please try again.',
                    severity: 'error',
                });
            }
        } else {
            setSnackbarConfig({
                open: true,
                message: 'Please fill in all fields, including your name.',
                severity: 'error',
            });
        }
    };

    const handleFileInfo = (row) => {
        setFileInfo(row);
        setFileInfoOpen(true);
    };

    const CustomToolbar = () => (
        <GridToolbarContainer>
            {uploadButton && (
                <Button onClick={handleUploadClick}>
                    <Typography variant="body2">{translations.upload}</Typography>
                    <FileUploadIcon sx={{ color: '#0c51a1', fontWeight: 'bold' }} />
                </Button>
            )}
            <GridToolbarExport sx={{ color: '#0c51a1', fontWeight: 'bold' }} />
            <GridToolbarColumnsButton sx={{ color: '#ffa600', fontWeight: 'bold' }} />
            <GridToolbarDensitySelector
                sx={{ color: '#1b9ddb', fontWeight: 'bold' }}
            />
        </GridToolbarContainer>
    );

    const columnsWithHandlers = columns.map((col) =>
        col.field === 'functions'
            ? {
                  ...col,
                  renderCell: (params) => (
                      <Box display="flex" alignItems="center">
                          <Tooltip title={translations.copyFileLink || 'Copy File Link'}>
                              <IconButton onClick={() => copyFileLink(params.row)} size="small">
                                  <ContentCopyIcon sx={{ color: '#ff671f', fontSize: '30px' }} />
                              </IconButton>
                          </Tooltip>
                          <Tooltip title={translations.downloadFile || 'Download File'}>
                              <IconButton onClick={() => handleDownload(params.row)} size="small">
                                  <CloudDownloadIcon sx={{ color: '#0c51a1', fontSize: '30px' }} />
                              </IconButton>
                          </Tooltip>
                          <Tooltip title={translations.deleteFile || 'Delete File'}>
                              <IconButton
                                  onClick={() => handleDelete(params.row)}
                                  size="small"
                                  color="error"
                              >
                                  <DeleteForeverIcon sx={{ fontSize: '30px' }} />
                              </IconButton>
                          </Tooltip>
                          <Tooltip title={translations.previewFile || 'Preview File'}>
                              <IconButton onClick={() => handlePreview(params.row)} size="small">
                                  <VisibilityIcon sx={{ color: '#41CFF9', fontSize: '30px' }} />
                              </IconButton>
                          </Tooltip>
                          <Tooltip title={translations.fileInformation || 'File Information'}>
                              <IconButton onClick={() => handleFileInfo(params.row)} size="small">
                                  <InfoIcon sx={{ color: '#DADA00', fontSize: '30px' }} />
                              </IconButton>
                          </Tooltip>
                      </Box>
                  ),
              }
            : col.field === 'Action'
            ? {
                  ...col,
                  renderCell: (params) => (
                      <ActionCell {...params} handleShow={handleShow} />
                  ),
              }
            : col.field === 'rrfGamAction'
            ? {
                  ...col,
                  renderCell: (params) => (
                      <ActionCell {...params} handleShow={handleShow} />
                  ),
              }
            : col.field === 'rrfLotAction'
            ? {
                  ...col,
                  renderCell: (params) => (
                      <ActionCell {...params} handleShow={handleShow} />
                  ),
              }
            : col
    );
    
    return (
        <div style={{ height: 800, width: '100%' }}>
            {loading && <LinearProgress />}
            <DataGridPro
                rows={data.rows}
                columns={columnsWithHandlers}
                pageSize={5}
                pagination
                components={{ Toolbar: CustomToolbar }}
                experimentalFeatures={{ newEditingApi: true }}
                getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 2,
                    bottom: params.isLastVisible ? 0 : 2,
                })}
            />
            <Snackbar
                open={snackbarConfig.open}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message={snackbarConfig.message}
                ContentProps={{ style: { backgroundColor: snackbarConfig.severity === 'success' ? '#4CAF50' : '#f44336' } }}
                action={
                    <Button color="inherit" size="small">
                        {translations.close || 'Close'}
                    </Button>
                }
            />
            <EditableModal
                open={show}
                handleClose={handleClose}
                rowId={rowId}
                selectedRowData={selectedRowData}
                columns={columns}
                handleEdit={handleEdit}
                setSelectedRowData={setSelectedRowData}
                formStyles={formStyles}
            />
            <FilesUploadComponent
                open={open}
                onClose={() => setOpen(false)}
                articleID={articleID}
                setArticleID={setArticleID}
                userName={userName}
                setUserName={setUserName}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                handleSubmit={handleSubmit}
            />
              <PreviewModal
                open={previewOpen}
                handleClose={() => setPreviewOpen(false)}
                previewFile={previewFile}
            />
               <FileInfoModal
                open={fileInfoOpen}
                onClose={() => setFileInfoOpen(false)}
                file={fileInfo}
            />
        </div>
    );
};

export default DataGridComponent;
