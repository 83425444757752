import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './views/auth/page';
import Dashboard from './views/dashboard/page';
import AccessDenied from './views/access/page';
import Files from './views/files/page';
import Reports from './views/reports/page';
import Forms from './views/forms/page';
import ContactCenterPage from './views/contactCenter/page';
import { AuthProvider } from './context/authContext';
import { ProtectedRoute } from './context/protectedRoute';
import { LicenseInfo } from '@mui/x-license-pro';
import RSSTracking from './views/rss/page';
import NotFound from './views/notFound/page';
import LanguageProvider from './hooks/languageProvider';
const muiXLicenseKey = process.env.REACT_APP_MUI_X_LICENSE_KEY;

LicenseInfo.setLicenseKey(muiXLicenseKey);

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/denied' element={<AccessDenied />} />
          <Route
            path='/home'
            element={<ProtectedRoute element={<Dashboard />} path='/home' />}
          />
          <Route
            path='/reports'
            element={<ProtectedRoute element={<Reports />} path='/reports' />}
          />
          <Route
            path='/rss/links'
            element={
              <ProtectedRoute element={<RSSTracking />} path='/rss/links' />
            }
          />
          <Route
            path='/forms'
            element={<ProtectedRoute element={<Forms />} path='/forms' />}
          />
           <Route
            path='/files'
            element={<ProtectedRoute element={<Files />} path='/files' />}
          />
          <Route
            path='/files/:country/:organization/:team'
            element={<ProtectedRoute element={<Files />} path='/files/:country/:organization/:team' />}
          />
          <Route path='/contact-center' element={<ContactCenterPage />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
