import React, { useState, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  Collapse,
  Select,
  FormControl,
} from '@mui/material';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Logout as LogoutIcon,
  Help as HelpIcon,
  Home as HomeIcon,
  Engineering as EngineeringIcon,
  HeadsetMic as HeadsetMicIcon,
  Widgets as WidgetsIcon,
  DynamicForm as DynamicFormIcon,
  Assessment as AssessmentIcon,
  FileOpen as FileOpenIcon,
  Settings as SettingsIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import LogoImage from '../assets/GNIECloud-Logo-500_white.png';
import useNavigateTo from '../hooks/navigation';
import useActivePage from '../hooks/activePage';
import { myMSALObj } from '../services/sso/authPopup';
import Cookies from 'js-cookie';
import useFetchProfilePhoto from '../hooks/fetchProfilePhoto';
import { LanguageContext } from '../context/languageContext';
import SettingsMenu from './settingsMenu';

const NavigationBar = ({ profilePicture }) => {
  const theme = useTheme();
  const { translations, language, changeLanguage } = useContext(LanguageContext);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigateTo = useNavigateTo();
  const { isActive } = useActivePage();
  const { ssoProfilePhotoUrl, standardProfilePhotoUrl } = useFetchProfilePhoto();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [helpMenuAnchor, setHelpMenuAnchor] = useState(null);
  const [servicesMenuOpen, setservicesMenuOpen] = useState(false);
  const [servicesMenuAnchor, setservicesMenuAnchor] = useState(null);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
  const [themeMode, setThemeMode] = useState('system');
  const authEndpoint = process.env.REACT_APP_ENDPOINT_AUTH;
  const systemEndpoint = process.env.REACT_APP_ENDPOINT_SYSTEM;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleHelpMenuOpen = (event) => {
    setHelpMenuAnchor(event.currentTarget);
  };

  const handleHelpMenuClose = (url = null) => {
    setHelpMenuAnchor(null);
    if (url) {
      window.location.href = url;
    }
  };

  const handleServicesMenuOpen = (event) => {
    if (isMobile) {
      setservicesMenuOpen(!servicesMenuOpen);
    } else {
      setservicesMenuAnchor(event.currentTarget);
    }
  };

  const handleServicesMenuClose = () => {
    setservicesMenuAnchor(null);
  };

  const handleSettingsMenuOpen = (event) => {
    setSettingsMenuAnchor(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchor(null);
  };

  const handleLogout = () => {
    const token = Cookies.get('token');

    if (!token) {
      console.error('No token found, redirecting to login');
      window.location.href = '/login';
      return;
    }

    const redirectToLogin = () => {
      Cookies.remove('token');
      console.log('Redirecting to login');
      window.location.href = '/login';
    };

    if (myMSALObj && myMSALObj.getAccount()) {
      myMSALObj.logout();
      redirectToLogin();
    } else {
      fetch(`${authEndpoint}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            console.log('Logout successful');
            redirectToLogin();
          } else {
            response
              .text()
              .then((text) => console.error('Logout failed:', text));
          }
        })
        .catch((error) => {
          console.error('Logout error:', error);
        });
    }
  };


  const getTheme = () => {
    if (themeMode === 'light') {
      return createTheme({ palette: { mode: 'light' } });
    } else if (themeMode === 'dark') {
      return createTheme({ palette: { mode: 'dark' } });
    } else {
      return theme;
    }
  };

  const navigationItems = [
    {
      name: translations['home'] || 'Home',
      icon: <HomeIcon />,
      onClick: () => navigateTo('/home'),
      path: '/home',
    },
    {
      name: translations['contactCenter'] || 'Contact Center',
      icon: <HeadsetMicIcon />,
      onClick: () => navigateTo('/contact-center'),
      path: '/contact-center',
    },
    {
      name: translations['fieldService'] || 'Field Service',
      icon: <EngineeringIcon />,
      onClick: () => navigateTo('/contact-center'),
      path: '/field-service',
    },
    {
      name: translations['services'] || 'Services',
      icon: <WidgetsIcon />,
      onClick: handleServicesMenuOpen,
      subItems: [
        {
          name: translations['files'] || 'Files',
          icon: <FileOpenIcon />,
          onClick: () => navigateTo('/files'),
        },
        {
          name: translations['forms'] || 'Forms',
          icon: <DynamicFormIcon />,
          onClick: () => navigateTo('/forms'),
          path: '/forms',
        },
        {
          name: translations['reports'] || 'Reports',
          icon: <AssessmentIcon />,
          onClick: () => navigateTo('/reports'),
          path: '/reports',
        },
      ],
    },
    {
      name: translations['settings'] || 'Settings',
      icon: <SettingsIcon />,
      onClick: handleSettingsMenuOpen,
      path: '/settings',
    },
  ];

  const renderNavigationItems = () => (
    <List>
      {navigationItems.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem
            button
            onClick={item.onClick}
            sx={{
              color: isActive(item.path) ? '#ffa600' : 'inherit',
              '&:hover': {
                backgroundColor: '#0c51a1',
                color: '#FFF',
              },
              border: '1px solid rgba(255, 255, 255, 0.2)',
              margin: '5px 0',
              borderRadius: '4px',
            }}
          >
            <ListItemIcon
              sx={{ color: isActive(item.path) ? '#ffa600' : 'inherit' }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} />
            {item.subItems && (
              <ListItemIcon>
                {servicesMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
            )}
          </ListItem>
          {item.subItems && (
            <Collapse in={servicesMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.subItems.map((subItem, subIndex) => (
                  <ListItem
                    button
                    key={subIndex}
                    onClick={subItem.onClick}
                    sx={{
                      color: isActive(subItem.path) ? '#ffa600' : 'inherit',
                      '&:hover': {
                        backgroundColor: '#0c51a1',
                        color: '#FFF',
                      },
                      border: '1px solid rgba(255, 255, 255, 0.2)',
                      margin: '5px 0',
                      borderRadius: '4px',
                      paddingLeft: theme.spacing(4),
                    }}
                  >
                    <ListItemIcon
                      sx={{ color: isActive(subItem.path) ? '#ffa600' : 'inherit' }}
                    >
                      {subItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={subItem.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  const drawer = (
    <Drawer
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 240,
          backgroundColor: '#053469',
          color: 'white',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ flexGrow: 1, padding: '0 8px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: 1,
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon sx={{ color: 'red' }} />
            </IconButton>
          </Box>
          {renderNavigationItems()}
        </Box>
        <Box sx={{ flexGrow: 0, padding: '8px' }}>
          <ListItem
            button
            onClick={() =>
              (window.location.href =
                'https://igt-apps.easyvista.com/s/ServiceDesk')
            }
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: '#0c51a1',
                color: '#FFF',
              },
              border: '1px solid rgba(255, 255, 255, 0.2)',
              margin: '5px 0',
              borderRadius: '4px',
            }}
          >
            <ListItemIcon>
              <ConfirmationNumberIcon sx={{ color: '#ffa600' }} />
            </ListItemIcon>
            <ListItemText primary="Submit a Ticket" />
          </ListItem>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: '#0c51a1',
                color: '#FFF',
              },
              border: '1px solid rgba(255, 255, 255, 0.2)',
              margin: '5px 0',
              borderRadius: '4px',
            }}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <ThemeProvider theme={getTheme()}>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <AppBar position="static" sx={{ backgroundColor: '#053469' }}>
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              src={LogoImage}
              alt="Logo"
              style={{ marginRight: '10px', height: '50px', cursor: 'pointer' }}
              onClick={() => navigateTo('/home')}
            />

            <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              GNIECloud Engage
            </Typography>

            <Avatar
              alt="Avatar"
              src={ssoProfilePhotoUrl || standardProfilePhotoUrl || ''}
              sx={{ width: 40, height: 40, cursor: 'pointer' }}
            />
            {isMobile ? null : (
              <>
                {navigationItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        margin: '0 8px',
                      }}
                    >
                      <IconButton
                        onClick={item.onClick}
                        style={{
                          color: isActive(item.path) ? '#ffa600' : 'white',
                        }}
                        aria-controls={item.name === 'Services' ? 'services-menu' : undefined}
                        aria-haspopup={item.name === 'Services' ? 'true' : undefined}
                      >
                        {item.icon}
                      </IconButton>
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: isMobile ? 'inherit' : '0.75rem',
                          marginTop: '-6px',
                          color: isActive(item.path) ? '#ffa600' : 'inherit'
                        }}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </React.Fragment>
                ))}
                <Menu
                  id="services-menu"
                  anchorEl={servicesMenuAnchor}
                  open={Boolean(servicesMenuAnchor)}
                  onClose={handleServicesMenuClose}
                  MenuListProps={{ onMouseLeave: handleServicesMenuClose }}
                  PaperProps={{
                    style: {
                      backgroundColor: '#0c51a1',
                      color: 'white',
                    },
                  }}
                >
                  <MenuItem onClick={() => navigateTo('/files')}>
                    <FileOpenIcon sx={{ marginRight: 1 }} />
                    {translations['files'] || 'Files'}
                  </MenuItem>
                  <MenuItem onClick={() => navigateTo('/forms')}>
                    <DynamicFormIcon sx={{ marginRight: 1 }} />
                    {translations['forms'] || 'Forms'}
                  </MenuItem>
                  <MenuItem onClick={() => navigateTo('/reports')}>
                    <AssessmentIcon sx={{ marginRight: 1 }} />
                    {translations['reporting'] || 'Reports'}
                  </MenuItem>
                </Menu>
                <Tooltip title="Help">
                  <div
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={handleHelpMenuOpen}
                      style={{ color: 'white' }}
                      aria-label="help"
                    >
                      <HelpIcon />
                    </IconButton>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: isMobile ? 'inherit' : '0.75rem',
                        color: 'white',
                        marginTop: '-6px',
                      }}
                    >
                      {translations['help'] || 'Help'}
                    </Typography>
                  </div>
                </Tooltip>
                <Menu
                  id="help-menu"
                  anchorEl={helpMenuAnchor}
                  open={Boolean(helpMenuAnchor)}
                  onClose={() => setHelpMenuAnchor(null)}
                  PaperProps={{
                    style: {
                      backgroundColor: '#0c51a1',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                    },
                  }}
                >
                  <a
                    href="https://igt-apps.easyvista.com/s/ServiceDesk"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    <MenuItem onClick={() => handleHelpMenuClose()}>
                      Submit a Ticket
                      <ConfirmationNumberIcon sx={{ ml: 2, color: '#ffa600' }} />
                    </MenuItem>
                  </a>
                </Menu>
                <SettingsMenu
                  settingsMenuAnchor={settingsMenuAnchor}
                  settingsMenuOpen={Boolean(settingsMenuAnchor)}
                  handleSettingsMenuClose={handleSettingsMenuClose}
                  themeMode={themeMode}
                  handleThemeChange={(event) => setThemeMode(event.target.value)}
                  language={language}
                  handleLanguageChange={(event) => changeLanguage(event.target.value)}
                />
              </>
            )}
            {!isMobile && (
              <Tooltip title="Logout">
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    color="inherit"
                    onClick={handleLogout}
                    sx={{ color: 'white' }}
                  >
                    <LogoutIcon sx={{ ml: 2 }} />
                  </IconButton>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: isMobile ? 'inherit' : '0.75rem',
                      color: 'white',
                      marginTop: '-6px',
                      marginLeft: '5px',
                    }}
                  >
                    {translations['logout'] || 'Logout'}
                  </Typography>
                </div>
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
        {isMobile && drawer}
      </Box>
    </ThemeProvider>
  );
};

export default NavigationBar;
