// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    padding: 4rem;
}

/* .cards-container {
    display: flex;
    flex-wrap: wrap;
} */

.card {
    margin: 2rem 2rem 2rem 0;
    padding: 0.5rem;
    box-shadow: 0 0 28px 4px rgba(0,0,0,0.1);
    /* transition: transform .3s ease; */
    /* cursor: pointer; */
    
}

/* .card:hover{
    transform: scale(1.1);
}

*/

.card__title{
    font-weight: 800;
    font-size: 1.5rem;
} 

.card__banner{
    display: flex;
    justify-content: space-between;
}

.card__image{
    display: flex;
    justify-content: flex-start;
}

.card__image img{
    border-radius: 50%;
    height: 50px;
}

.location{
    margin: 1rem 0;
}

.location p{
    margin: 0;
    font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/views/rss/rssstyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;;;GAGG;;AAEH;IACI,wBAAwB;IACxB,eAAe;IACf,wCAAwC;IACxC,oCAAoC;IACpC,qBAAqB;;AAEzB;;AAEA;;;;CAIC;;AAED;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,iBAAiB;AACrB","sourcesContent":[".App {\r\n    padding: 4rem;\r\n}\r\n\r\n/* .cards-container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n} */\r\n\r\n.card {\r\n    margin: 2rem 2rem 2rem 0;\r\n    padding: 0.5rem;\r\n    box-shadow: 0 0 28px 4px rgba(0,0,0,0.1);\r\n    /* transition: transform .3s ease; */\r\n    /* cursor: pointer; */\r\n    \r\n}\r\n\r\n/* .card:hover{\r\n    transform: scale(1.1);\r\n}\r\n\r\n*/\r\n\r\n.card__title{\r\n    font-weight: 800;\r\n    font-size: 1.5rem;\r\n} \r\n\r\n.card__banner{\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.card__image{\r\n    display: flex;\r\n    justify-content: flex-start;\r\n}\r\n\r\n.card__image img{\r\n    border-radius: 50%;\r\n    height: 50px;\r\n}\r\n\r\n.location{\r\n    margin: 1rem 0;\r\n}\r\n\r\n.location p{\r\n    margin: 0;\r\n    font-size: 0.8rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
