import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./authContext";

const ProtectedRoute = ({ element, path }) => {
  const { isAuthenticated, accessiblePaths } = useAuth();
  const location = useLocation();

  // Check if the path is in the user's accessiblePaths
  const hasPermission = isAuthenticated && accessiblePaths.includes(path);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!hasPermission) {
    return <Navigate to="/denied" state={{ from: location }} replace />;
  }

  return element;
};

export { ProtectedRoute };
