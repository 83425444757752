import React, { useState, useEffect } from 'react';
import { LanguageContext } from '../context/languageContext';

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default to English
  const [translations, setTranslations] = useState({});

  const loadTranslations = (lang) => {
    import(`../locales/${lang}.json`)
      .then((module) => {
        setLanguage(lang);
        setTranslations(module.default);
      })
      .catch(() => {
        // Fallback to English if translation file for selected language is not found
        import('../locales/en.json')
          .then((module) => {
            setLanguage('en');
            setTranslations(module.default);
          });
      });
  };

  useEffect(() => {
    const storedLang = localStorage.getItem('engage_app_lang');
    if (storedLang) {
      loadTranslations(storedLang);
    } else {
      const browserLang = navigator.language.split('-')[0];
      loadTranslations(browserLang);
    }
  }, []);

  const changeLanguage = (lang) => {
    localStorage.setItem('engage_app_lang', lang);
    loadTranslations(lang);
  };

  return (
    <LanguageContext.Provider value={{ translations, language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
