import React from 'react';
import { MenuItem, Typography, Select, FormControl } from '@mui/material';
import { LanguageIcon } from '../assets/icons/icons';

const LanguageSetting = ({ language, handleLanguageChange }) => (
    <MenuItem>
        <LanguageIcon sx={{ marginRight: 1, color: '#053469' }} />
        <Typography variant="body2" sx={{ marginRight: 2,  }}>Language:</Typography>
        <FormControl sx={{ width: '100%' }}>
            <Select value={language} onChange={handleLanguageChange} fullWidth>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="es">Spanish</MenuItem>
                <MenuItem value="cs">Czech</MenuItem>
                {/* <MenuItem value="de">German</MenuItem>
                <MenuItem value="fr">French</MenuItem>
                <MenuItem value="it">Italian</MenuItem> */}
                <MenuItem value="pl">Polish</MenuItem>
                {/* <MenuItem value="pt">Portuguese</MenuItem>
                <MenuItem value="sk">Slovak</MenuItem> */}
            </Select>
        </FormControl>
    </MenuItem>
);

export default LanguageSetting;
