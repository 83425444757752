import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SensorsIcon from '@mui/icons-material/Sensors';
import RefreshIcon from '@mui/icons-material/Refresh';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment/moment';
// Leaflet Map Imports
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
// snackbar notification
import Snackbar from '@mui/material/Snackbar';
// MUI Dialog components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
// User details components
import Cookies from 'js-cookie';

const VentusSearch = () => {
  const [serial, setSerial] = useState('');
  const [device, setDevice] = useState(null);
  const [error, setError] = useState(null);
  const [ping, setPing] = useState(null);
  const [cellularCheck, setCellularCheck] = useState(null);
  const [cellularCheckPerformed, setCellularCheckPerformed] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  // const [showMap, setShowMap] = useState(false); // State to control map visibility
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  // User Details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const usersEndpoint = process.env.REACT_APP_ENDPOINT_QA_USERS;
      const token = Cookies.get('token');
      if (!token) {
        console.error('Access token is not available.');
        return;
      }

      try {
        const response = await axios.get(`${usersEndpoint}/user-details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          setUserDetails(response.data);
        }
      } catch (error) {
        // console.error('Failed to fetch user details:', error);
        setUserDetails({});
      }
    };

    fetchUserDetails();
    // console.log(fetchUserDetails());
  }, []);
  // Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // map marker
  const customIcon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/128/684/684908.png',
    iconSize: [38, 38],
  });

  // Searching for Device Information
  const handleSearch = async () => {
    if (!serial) {
      alert('Please enter a serial number');
      return;
    }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/ventus/${serial}`
      );
      setDevice(res.data.data);
      setLat(res.data.data.unit.lat);
      setLng(res.data.data.unit.lng);
      setError(null);
      setSnackbarMessage('Device Search Successful');
      setSnackbarOpen(true);
      setCellularCheck(null); // Reset health check value on new search
      setCellularCheckPerformed(false); // hide health check on new search
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`Error: ${error.message}`);
      setDevice(null);
    }
  };

  // Clearing Search Results
  const handleClear = () => {
    setSerial('');
    setDevice(null);
    setError(null);
    setPing(null);
    setCellularCheck(null);
    setCellularCheckPerformed(false);
    setLat('');
    setLng('');
    setSnackbarMessage('Device Search Cleared');
    setSnackbarOpen(true);
  };

  // Health Check
  const handleCellularCheck = async () => {
    try {
      // console.log(`Health Check on: ${device.unit.serial}`);
      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/ventus/cellularCheck/${device.unit.serial}`
      );
      setCellularCheck(res.data.data.active.rsrp);
      setCellularCheckPerformed(true);
      // console.log(cellularCheck);
      setError(null);
      setSnackbarMessage('Health Check Successful');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error Health Check on device:', error);
      setError(`Error: ${error.message}`);
      setCellularCheck(null);
      // alert('Ping Unsuccessful');
    }
  };

  // Pinging Device
  const handlePing = async () => {
    try {
      // console.log(`Pinging ${device.unit.hostname}`);
      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/ventus/ping/${device.unit.hostname}`
      );
      setPing(res.data);
      // console.log(ping);
      setError(null);
      setSnackbarMessage('Ping Successful');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error pinging device:', error);
      setError(`Error: ${error.message}`);
      setPing(null);
      // alert('Ping Unsuccessful');
    }
  };

  // Reboot Device
  const handleReboot = () => {
    setDialogOpen(true);
  };

  const handleConfirmReboot = async () => {
    try {
      const rebootData = {
        locationId: null, // Replace with actual values
        hostname: `${device.unit.hostname.toUpperCase()}`,
        serialNumber: `${device.unit.serial}`,
        device: 'Ventus',
        rebooted: 'YES', // or false based on your logic
        associateEmail: `${userDetails.email}`,
      };

      const res = await axios.post(
        // `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/reboot`,
        `http://localhost:8080/v1/engage/contactcenter/comm/reboot`,
        rebootData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log(`Device rebooted: ${device.unit.serial}`);
      setDialogOpen(false);
      setSnackbarMessage('Device Rebooted');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting reboot data:', error);
      // Handle error accordingly
    }
  };

  const handleCancelReboot = async () => {
    try {
      const rebootData = {
        locationId: null, // Replace with actual values
        hostname: `${device.unit.hostname.toUpperCase()}`,
        serialNumber: `${device.unit.serial}`,
        device: 'Ventus',
        rebooted: 'NO', // or false based on your logic
        associateEmail: `${userDetails.email}`,
      };

      const res = await axios.post(
        // `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/reboot`,
        `http://localhost:8080/v1/engage/contactcenter/comm/reboot`,
        rebootData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log(`Device Not Rebooted: ${device.unit.serial}`);
      setDialogOpen(false);
      setSnackbarMessage('Device Reboot Rejected');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting reboot data:', error);
      // Handle error accordingly
    }
  };
  return (
    <>
      <div className='container' style={{ display: 'flex' }}>
        <div style={{ flex: 1, padding: '10px' }}>
          {/* <h1>Search Device</h1> */}
          <input
            type='text'
            value={serial}
            onChange={e => setSerial(e.target.value)}
            placeholder='Enter Ventus serial'
          />
          <button onClick={handleSearch}>Search</button>
          <button onClick={handleClear}>Clear</button>
          <br />
          <br />
          <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {device && (
              <Card
                variant='outlined'
                style={{ display: 'flex', width: '100%' }}
              >
                <CardContent style={{ flex: 1, display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    {device.unit.status === 'up' ? (
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        Status:{' '}
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                          UP
                        </span>
                      </Typography>
                    ) : (
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        Status:{' '}
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                          DOWN
                        </span>
                      </Typography>
                    )}
                    {cellularCheckPerformed && (
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        Cellular Check:{' '}
                        {cellularCheck < -105 ? (
                          <span style={{ color: 'red', fontWeight: 'bold' }}>
                            BAD ({cellularCheck})
                          </span>
                        ) : cellularCheck > -105 && cellularCheck < -95 ? (
                          <span style={{ color: 'orange', fontWeight: 'bold' }}>
                            FAIR ({cellularCheck})
                          </span>
                        ) : cellularCheck >= -95 ? (
                          <span style={{ color: 'green', fontWeight: 'bold' }}>
                            GOOD ({cellularCheck})
                          </span>
                        ) : null}
                      </Typography>
                    )}
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Hostname:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.unit.hostname.toUpperCase()}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Serial:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.unit.serial}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Model:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.unit.model}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Device Type:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.unit.deviceType}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Location:
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      <span style={{ color: '#154db4' }}>
                        {device.siteInfo.sitename}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      <span style={{ color: '#154db4' }}>
                        {device.siteInfo.street1}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      <span style={{ color: '#154db4' }}>
                        {device.siteInfo.street2}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      <span style={{ color: '#154db4' }}>
                        {device.siteInfo.city}, {device.siteInfo.state}{' '}
                        {device.siteInfo.zip}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Coordinates:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.unit.lng}, {device.unit.lat}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Last Status Change:{' '}
                      <span style={{ color: '#154db4' }}>
                        {moment
                          .unix(device.unit.time.lastStatusChange)
                          .format('dddd, MMMM Do, YYYY h:mm A')}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Last Power Status Update:{' '}
                      <span style={{ color: '#154db4' }}>
                        {moment
                          .unix(device.unit.time.last_power_status_update)
                          .format('dddd, MMMM Do, YYYY h:mm A')}
                      </span>
                    </Typography>
                  </div>
                  {/* {showMap && ( */}
                  <div
                    style={{
                      marginLeft: '20px',
                      width: '50%',
                      flexShrink: 0, // Prevent the map from shrinking
                    }}
                  >
                    <MapContainer
                      center={[lat, lng]}
                      zoom={17}
                      style={{ height: '50vh', width: '100%' }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      />
                      <Marker position={[lat, lng]} icon={customIcon}></Marker>
                    </MapContainer>
                  </div>
                  {/* )} */}
                </CardContent>
                {/* Action Buttons Sidepan */}
                <div
                  style={{
                    borderLeft: '1px solid #ccc',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {/* <Tooltip title='Show Map'>
                    <IconButton onClick={() => setShowMap(!showMap)}>
                      <GpsFixedIcon />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title='Ping Device'>
                    <IconButton onClick={() => handlePing()}>
                      <SensorsIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Cellular Health Check'>
                    <IconButton onClick={() => handleCellularCheck()}>
                      <HealthAndSafetyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Reboot Device'>
                    <IconButton onClick={() => handleReboot()}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        ContentProps={{
          style: {
            backgroundColor: '#4CAF50',
          },
        }}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleCancelReboot}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <WarningIcon sx={{ color: 'red' }} />
          {' Warning!'}
        </DialogTitle>
        <DialogContent>
          <Typography variant='body2'>
            Rebooting this device will disrupt service for the customer. Are you
            sure you want to do this?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelReboot} color='primary'>
            No
          </Button>
          <Button onClick={handleConfirmReboot} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VentusSearch;
