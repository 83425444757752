/* eslint-disable jsx-a11y/iframe-has-title */
function Map() {
    return (
      <div className="iframe-container">
        <iframe
          src="https://www.rainviewer.com/map.html"
          style={{ width: '100%', height: '70vh', border: 'none' }}
        ></iframe>
      </div>
    );
  }
  
  export default Map;
  