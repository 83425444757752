import React, { useState, useEffect } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    Typography,
    CircularProgress,
    RadioGroup,
    FormControlLabel,
    Radio,
    Snackbar
} from '@mui/material';
import axios from 'axios';

const renderField = (field, value, handleChange) => {
    const readOnlyFields = ['userId', 'email']; // Define read-only field names

    switch (field.type) {
        case 'text':
            return (
                <TextField
                    fullWidth
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    value={value}
                    onChange={handleChange}
                    required={field.required}
                    multiline={field.multiline}
                    rows={field.rows}
                    helperText={field.helperText}
                    disabled={readOnlyFields.includes(field.name)}
                />
            );
        case 'date':
            return (
                <TextField
                    fullWidth
                    key={field.name}
                    name={field.name}
                    label={field.label}
                    type="date"
                    value={value}
                    onChange={handleChange}
                    required={field.required}
                    InputLabelProps={{ shrink: true }}
                />
            );
        case 'select':
            return (
                <FormControl fullWidth key={field.name}>
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                        name={field.name}
                        value={value}
                        onChange={handleChange}
                        required={field.required}
                    >
                        {Array.isArray(field.options) &&
                            field.options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            );
        case 'radio':
            return (
                <FormControl component="fieldset" key={field.name}>
                    <Typography variant="body1">{field.label}</Typography>
                    <RadioGroup
                        row
                        name={field.name}
                        value={value}
                        onChange={handleChange}
                    >
                        {Array.isArray(field.options) &&
                            field.options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            ))}
                    </RadioGroup>
                </FormControl>
            );
        default:
            return null;
    }
};

const FormComponent = ({ formConfig, userDetails, onChange, onSubmit }) => {
    const [formData, setFormData] = useState(
        formConfig.fields.reduce((acc, field) => {
            acc[field.name] = field.value || ''; // Set initial value or default to empty string
            return acc;
        }, {})
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    // Track the values of 'isSecondTimeReturn', 'isTech', and 'openCase'
    const isSecondTimeReturn = formData.isSecondTimeReturn;
    const isTech = formData.isTech;
    const openCase = formData.openCase;

    useEffect(() => {
        if (userDetails) {
            setFormData((prevData) => ({
                ...prevData,
                email: userDetails.email,
                userId: userDetails.userId,
            }));
        }
    }, [userDetails]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        onChange({ ...formData, [name]: value }); // Notify parent component of change
        console.log(`Field ${name} changed to ${value}`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios({
                method: formConfig.method,
                url: formConfig.apiEndpoint,
                data: formData,
            });
            console.log('Form submitted successfully:', response.data);
            onSubmit(response.data);
            setSnackbarConfig({
                open: true,
                message: 'Form submitted successfully!',
                severity: 'success',
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            setSnackbarConfig({
                open: true,
                message: 'Error submitting form. Please try again.',
                severity: 'error',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => ({ ...prev, open: false }));
    };

    // Filter fields to conditionally render 'returnDate', 'fstNumber', and 'caseNumber'
    const filteredFields = formConfig.fields.filter((field) => {
        if (field.name === 'returnDate' && isSecondTimeReturn !== 'Yes') {
            return false;
        }
        if (field.name === 'fstNumber' && isTech !== 'yes') {
            return false;
        }
        if (field.name === 'caseNumber' && openCase !== 'yes') {
            return false;
        }
        return true;
    });

    // Debugging: Check the current state values and filtered fields
    console.log("Current form data:", formData);
    console.log("Filtered fields:", filteredFields);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {filteredFields.map((field) => (
                        <Grid
                            item
                            xs={12}
                            sm={field.type === 'radio' ? 12 : 6}
                            key={field.name}
                        >
                            {renderField(field, formData[field.name], handleChange)}
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        {isSubmitting ? (
                            <CircularProgress />
                        ) : (
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={snackbarConfig.open}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message={snackbarConfig.message}
                ContentProps={{ style: { backgroundColor: snackbarConfig.severity === 'success' ? '#4CAF50' : '#f44336' } }}
                action={
                    <Button color="inherit" size="small">
                        Close
                    </Button>
                }
            />
        </>
    );
};

export default FormComponent;
