import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  DialogContentText,
  Alert,
  Typography,
} from "@mui/material";

// TODO: Error Handling, Input Validation
export default function AuthModal({ isDialogOpen, setIsDialogOpen }) {
  const [activeTab, setActiveTab] = useState(0);
  const [authError, setAuthError] = useState("");

  const handleLogin = async (loginJson) => {
    const res = await fetch("http://localhost:3004/v1/engage/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loginJson),
    });
    if (!res.ok) {
      const resBody = await res.json();
      setAuthError(resBody.error);
    } else {
      setAuthError("");
    }
  };

  const handleActivate = async (activateJson) => {
    const res = await fetch("http://localhost:3004/v1/engage/auth/activate", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(activateJson),
    });
    if (!res.ok) {
      const resBody = await res.json();
      setAuthError(resBody.error);
    } else {
      setAuthError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    // If active tab is 0, login else create account
    if (activeTab == 0) {
      await handleLogin(formJson);
    } else {
      const activateData = {
        email: formJson.email,
        password: formJson.password,
        inviteCode: formJson.inviteCode,
      };
      await handleActivate(activateData);
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      PaperProps={{
        component: "form",
        onSubmit: (event) => handleSubmit(event),
      }}
    >
      {activeTab == 0 && (
        <>
          <DialogTitle>3rd Party Login</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin='normal'
              id='email'
              name='email'
              label='Email Address'
              type='email'
              fullWidth
              variant='standard'
            />
            <TextField
              required
              margin='normal'
              id='password'
              name='password'
              label='Password'
              type='password'
              fullWidth
              variant='standard'
            />
            <DialogContentText>
              Need an account?
              <Button onClick={() => setActiveTab(1)} sx={{ paddingBlock: 2 }}>
                Create Account
              </Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Typography
              variant='subtitle1'
              sx={{ color: "red", mr: "auto", pl: 2 }}
            >
              {authError}
            </Typography>
            <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
            <Button type='submit'>Login</Button>
          </DialogActions>
        </>
      )}

      {activeTab == 1 && (
        <>
          <DialogTitle>Create 3rd Party Account</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin='normal'
              id='email'
              name='email'
              label='Email Address'
              type='email'
              fullWidth
              variant='standard'
            />
            <TextField
              required
              margin='normal'
              id='inviteCode'
              name='inviteCode'
              label='Invite Code'
              type='text'
              fullWidth
              variant='standard'
            />
            <TextField
              required
              margin='normal'
              id='password'
              name='password'
              label='Password'
              type='password'
              fullWidth
              variant='standard'
            />
            <TextField
              required
              margin='normal'
              id='verifyPassword'
              name='verifyPassword'
              label='Re-enter Password'
              type='password'
              fullWidth
              variant='standard'
            />
            <DialogContentText>
              Already have an account?
              <Button onClick={() => setActiveTab(0)} sx={{ paddingBlock: 2 }}>
                Login
              </Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Typography
              variant='subtitle1'
              sx={{ color: "red", mr: "auto", pl: 2 }}
            >
              {authError}
            </Typography>
            <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
            <Button type='submit'>Create Account</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
