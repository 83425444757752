import { UserAgentApplication, InteractionRequiredAuthError } from "msal";
import axios from "axios";
import { msalConfig, loginRequest, tokenRequest } from "./authConfig";



const usersEndpoint = process.env.REACT_APP_ENDPOINT_QA_USERS;  

// Create the main myMSALObj instance
export const myMSALObj = new UserAgentApplication(msalConfig);

// Variable to hold the promise for the current or most recent token acquisition
let tokenAcquisitionPromise = null;

// Function to get an access token using popup
export async function getTokenPopup(request) {
  try {
    if (
      !tokenAcquisitionPromise ||
      tokenAcquisitionPromise.expiresOn < new Date()
    ) {
      tokenAcquisitionPromise = myMSALObj.acquireTokenPopup(request);
    }
    const response = await tokenAcquisitionPromise;
    return response;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      tokenAcquisitionPromise = null; // Reset promise if interaction is required
    }
    throw error;
  }
}

// Function to fetch user data from Microsoft Graph API
async function fetchUserData(accessToken) {
  try {
    if (!accessToken) {
      console.error("Access token is null.");
      return null;
    }

    // Fetch user data
    const userDataResponse = await axios.get(
      "https://graph.microsoft.com/beta/me",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (userDataResponse.status !== 200) {
      console.error("Failed to fetch user data:", userDataResponse.statusText);
      return null;
    }

    const userData = userDataResponse.data;

    // Initialize an array to store all group memberships
    let allGroups = [];
    let nextLink = "https://graph.microsoft.com/beta/me/memberOf";

    // Loop to handle pagination for group memberships
    do {
      const groupMembershipsResponse = await axios.get(nextLink, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (groupMembershipsResponse.status !== 200) {
        console.error(
          "Failed to fetch user's group memberships:",
          groupMembershipsResponse.statusText
        );
        return null;
      }

      const groupMembershipsData = groupMembershipsResponse.data;

      // Add current page of groups to allGroups
      const currentPageGroups = groupMembershipsData.value
        .map((group) => ({
          displayName: group.displayName,
          id: group.id,
        }))
        .filter((group) => group.displayName && group.id); // Ensure both properties exist
      allGroups = allGroups.concat(currentPageGroups);

      // Update nextLink for the next iteration
      nextLink = groupMembershipsData["@odata.nextLink"];
    } while (nextLink);
    // console.log("All groups:", allGroups);
    // Construct user object with relevant data including all group memberships
    const user = {
      userId: userData.id,
      userName: userData.userPrincipalName,
      // email: userData.mail,
      email: userData.userPrincipalName,
      firstName: userData.givenName,
      lastName: userData.surname,
      displayName: userData.displayName,
      ldap: userData.onPremisesSamAccountName,
      jobTitle: userData.jobTitle,
      department: userData.department,
      officeLocation: userData.officeLocation,
      country:userData.usageLocation,
      userType: "standard",
      ssoEnabled: true,
      groups: allGroups.filter((group) => group !== null), // Filter out null values
    };

    return user;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}


// Function to handle sign-in using loginPopup
export async function signIn() {
  try {
    const loginResponse = await myMSALObj.loginPopup(loginRequest);
    const account = myMSALObj.getAccount();
    if (account) {
      console.log("User logged in successfully:", account.name);
      const accessTokenResponse = await getTokenPopup(tokenRequest);
      const userData = await fetchUserData(accessTokenResponse.accessToken);
      if (userData) {
        // Extract just the display names for the groups
        const groupDisplayNames = userData.groups.map(
          (group) => group.displayName
        );

        // Update the user object to include only group display names
        const userWithGroupNames = {
          ...userData,
          groups: groupDisplayNames,
          token: accessTokenResponse.accessToken,
        };

        // Remove the groups property for the POST request
        const { groups, ...userWithoutGroups } = userWithGroupNames;

        // Ensure all fields are defined and set to null if undefined
        const userPayload = Object.fromEntries(
          Object.entries(userWithoutGroups).map(([key, value]) => [key, value ?? null])
        );

        // Call the createSSOUser endpoint to create or update the user in the database
        await axios.post(
          `${usersEndpoint}/create-sso`,
          userPayload
        );

        await axios.put(
          `${usersEndpoint}/last-login`, { userId: userData.userId });

        return userWithGroupNames; // Return user data including mapped group names
      }
    }
  } catch (error) {
    console.error("Login failed:", error);
    throw error; // Ensure errors are propagated
  }
}

// Function to handle sign-out
export function signOut() {
  myMSALObj.logout();
}
 