import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import ThemeSetting from './themeSetting';
import LanguageSetting from './languageSetting';

const SettingsMenu = ({
    settingsMenuAnchor,
    settingsMenuOpen,
    handleSettingsMenuClose,
    themeMode,
    handleThemeChange,
    language,
    handleLanguageChange,
}) => (
    <Menu
        id="settings-menu"
        anchorEl={settingsMenuAnchor}
        open={settingsMenuOpen}
        onClose={handleSettingsMenuClose}
        PaperProps={{
            style: {
                backgroundColor: '#FFFF',
                width: 250,
            },
        }}
    >
        <MenuItem disabled>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#053469' }}>
                Settings
            </Typography>
        </MenuItem>
        {/* <ThemeSetting themeMode={themeMode} handleThemeChange={handleThemeChange} /> */}
        <LanguageSetting language={language} handleLanguageChange={handleLanguageChange} />
    </Menu>
);

export default SettingsMenu;
