// Define styles for various elements in the form
export const formStyles = {
  paper: {
    padding: "2rem",
    margin: "2rem auto",
    maxWidth: "1000px",
    // background: "#fff",
    // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    border: "none"
  },
  heading: {
    color: "#0C51A1",
    marginBottom: "1rem",
  },
  label: {
    color: "#0C51A1",
  },
  requiredAsterisk: {
    color: "red",
    fontWeight: "bold",
  },
  input: {
    background: "rgba(141, 200, 232, 0.2)",
    borderRadius: "5px",
    width: "100%",
  },
  button: {
    background: "#ff671f",
    color: "#fff",
    marginTop: "1rem",
    "&:hover": {
      background: "#ffa300",
    },
  },
  radioGroup: {
    flexDirection: "row",
  },

};

export default formStyles;