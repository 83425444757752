import React, { useState, useContext, useEffect } from 'react';
import {
	Box,
	Grid,
	Typography,
	Button,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import DropDown from '../../components/dropDown';
import FormComponent from '../../components/form';
import {
	lotteryRepairFormConfig,
	gamingRepairFormConfig,
	incorrectCaseFormConfig,
	fstOvertimeFormConfig,
	fieldRepairFormConfig,
	californiaPTLFormConfig,
	forwardToSiteTXConfig,
} from '../../utils/formConfigs';
import NavigationBar from '../../components/navigationBar';
import { formMenuData } from '../../utils/menuData';
import { LanguageContext } from '../../context/languageContext';
import Cookies from 'js-cookie';
import axios from 'axios';

const Forms = () => {
	const translations = useContext(LanguageContext);

	const [selectedCountry, setSelectedCountry] = useState('United States');
	const [selectedTeam, setSelectedTeam] = useState('');
	const [selectedFormType, setSelectedFormType] = useState('');
	const [showForm, setShowForm] = useState(false);
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const [isSecondTimeReturn, setIsSecondTimeReturn] = useState('Yes');
	const [isTech, setIsTech] = useState('yes'); // Ensure initial state is 'yes' or 'no'
	const [openCase, setOpenCase] = useState('yes'); // Ensure initial state is 'yes' or 'no'
	const [userDetails, setUserDetails] = useState(null);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const fetchUserDetails = async () => {
			const usersEndpoint = process.env.REACT_APP_ENDPOINT_QA_USERS;
			const token = Cookies.get('token');
			if (!token) {
				console.error('Access token is not available.');
				return;
			}

			try {
				const response = await axios.get(`${usersEndpoint}/user-details`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.data) {
					setUserDetails(response.data);
				}
			} catch (error) {
				console.error('Failed to fetch user details:', error);
				setUserDetails({});
			}
		};

		fetchUserDetails();
	}, []);

	const teams =
		formMenuData(translations).find((data) => data.country === selectedCountry)
			?.teams || [];
	const forms = teams.find((team) => team.name === selectedTeam)?.forms || [];

	const handleCountryChange = (event) => {
		setSelectedCountry(event.target.value);
		setSelectedTeam('');
		setSelectedFormType('');
		setShowForm(false);
	};

	const handleTeamChange = (event) => {
		setSelectedTeam(event.target.value);
		setSelectedFormType('');
		setShowForm(false);
	};

	const handleFormTypeChange = (event) => {
		setSelectedFormType(event.target.value);
	};

	const handleShowFormClick = () => {
		if (selectedFormType) setShowForm(true);
	};

	// TODO: Add translation form configurations for each form type 
	// const formConfigs = {
	// 	FSTOvertimeForm: fstOvertimeFormConfig(translations),
	// 	IncorrectCaseForm: incorrectCaseFormConfig(translations),
	// 	FieldRepairForm: fieldRepairFormConfig(translations),
	// 	LotteryRepairWorklogForm: lotteryRepairFormConfig(translations),
	// 	GamingRepairWorklogForm: gamingRepairFormConfig(translations),
	// };

	const formConfigs = {
		FSTOvertimeForm: fstOvertimeFormConfig,
		IncorrectCaseForm: incorrectCaseFormConfig,
		FieldRepairForm: fieldRepairFormConfig,
		LotteryRepairWorklogForm: lotteryRepairFormConfig,
		GamingRepairWorklogForm: gamingRepairFormConfig,
	};


	const handleSecondTimeReturnChange = value => {
		setIsSecondTimeReturn(value);
	  };
	
	  const handleIsTechChange = value => {
		setIsTech(value);
		console.log(`isTech changed to ${value}`);
	  };
	
	  const handleOpenCaseChange = value => {
		setOpenCase(value);
		console.log(`openCase changed to ${value}`);
	  };
	const renderForm = () => {
		if (!showForm) return null;

		const formConfig = forms.find(
			(form) => form.componentKey === selectedFormType
		);

		if (formConfig?.componentKey && formConfigs[formConfig.componentKey]) {
			const dynamicFormConfig = { ...formConfigs[formConfig.componentKey] };

			if (formConfig.componentKey === 'LotteryRepairWorklogForm') {
				dynamicFormConfig.fields = dynamicFormConfig.fields.filter(
					(field) => field.name !== 'returnDate' || isSecondTimeReturn === 'Yes'
				);
			}

			if (formConfig.componentKey === 'FSTOvertimeForm') {
				dynamicFormConfig.fields = dynamicFormConfig.fields.filter((field) => {
					if (field.name === 'fstNumber' && isTech !== 'yes') return false;
					if (field.name === 'caseNumber' && openCase !== 'yes') return false;
					return true;
				});
			}

			return (
				<FormComponent
					formConfig={dynamicFormConfig}
					userDetails={userDetails}
					onChange={(data) => {
						if (data.isSecondTimeReturn !== undefined) {
							handleSecondTimeReturnChange(data.isSecondTimeReturn);
						}
						if (data.isTech !== undefined) {
							handleIsTechChange(data.isTech);
						}
						if (data.openCase !== undefined) {
							handleOpenCaseChange(data.openCase);
						}
					}}
					onSubmit={(data) => console.log('Form submitted:', data)}
				/>
			);
		} else {
			return (
				<Typography>
					{translations.selectFormToBegin || 'Select a form to begin'}
				</Typography>
			);
		}
	};

	return (
		<>
			<NavigationBar />
			<Box sx={{ flexGrow: 1, margin: '20px' }}>
				<Grid container spacing={2}>
					{isMobile && (
						<Grid item xs={12}>
							<Button
								variant="contained"
								onClick={() => setIsMenuVisible(!isMenuVisible)}
								sx={{
									mb: 2,
									flex: 1,
									backgroundColor: isMenuVisible ? '#0a417a' : '#0c51a1',
									'&:hover': {
										backgroundColor: isMenuVisible ? '#08305a' : '#0b4690',
									},
								}}
							>
								{isMenuVisible
									? translations.hideFilters || 'Hide Filters'
									: translations.selectFilters || 'Select Filters'}
							</Button>
						</Grid>
					)}
					{(!isMobile || isMenuVisible) && (
						<Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: { xs: 'column', md: 'row' },
									gap: 2,
									padding: '20px',
									height: '100%',
									width: { xs: 'auto', md: '90vw' },
								}}
							>
								<Box sx={{ flex: 1 }}>
									<DropDown
										items={formMenuData(translations).map((data) => ({
											value: data.country,
											label: data.country,
										}))}
										selectedValue={selectedCountry}
										onChange={handleCountryChange}
										placeholder={translations.selectCountry || 'Select Country'}
									/>
								</Box>
								<Box sx={{ flex: 1 }}>
									<DropDown
										items={teams.map((team) => ({
											value: team.name,
											label: team.name,
										}))}
										selectedValue={selectedTeam}
										onChange={handleTeamChange}
										placeholder={translations.selectTeam || 'Select Team'}
									/>
								</Box>
								<Box sx={{ flex: 1 }}>
									<DropDown
										items={forms.map((form) => ({
											value: form.componentKey,
											label: form.name,
										}))}
										selectedValue={selectedFormType}
										onChange={handleFormTypeChange}
										placeholder={
											translations.selectFormType || 'Select Form Type'
										}
									/>
									<Button
										variant="contained"
										onClick={handleShowFormClick}
										sx={{ mt: 2 }}
										disabled={!selectedFormType}
									>
										{translations.loadForm || 'Load Form'}
									</Button>
								</Box>
							</Box>
						</Grid>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					md={8}
					order={{ xs: 2, md: 1 }}
					sx={{ mt: { xs: '10%', md: 'auto' } }}
				>
					{renderForm()}
				</Grid>
			</Box>
		</>
	);
};

export default Forms;
