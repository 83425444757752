import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SensorsIcon from '@mui/icons-material/Sensors';
import RefreshIcon from '@mui/icons-material/Refresh';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment/moment';
// Leaflet Map Imports
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
// snackbar notification
import Snackbar from '@mui/material/Snackbar';
import { LanguageContext } from '../../context/languageContext';
// MUI Dialog components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
// User details components
import Cookies from 'js-cookie';

const HughesSearch = () => {
  const translations = useContext(LanguageContext);
  const [serial, setSerial] = useState('');
  const [device, setDevice] = useState(null);
  const [error, setError] = useState(null);
  const [ping, setPing] = useState(null);
  const [cellularCheck, setCellularCheck] = useState(null);
  const [cellularCheckPerformed, setCellularCheckPerformed] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [signalStrength, setSignalStrength] = useState('');

  // regex to capture all numbers before the parenthesis of signal_strength
  const match = signalStrength.match(/^\d+/);
  const signalStrengthClean = match ? match[0] : 'No match found';

  // User Details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const usersEndpoint = process.env.REACT_APP_ENDPOINT_QA_USERS;
      const token = Cookies.get('token');
      if (!token) {
        console.error('Access token is not available.');
        return;
      }

      try {
        const response = await axios.get(`${usersEndpoint}/user-details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          setUserDetails(response.data);
        }
      } catch (error) {
        // console.error('Failed to fetch user details:', error);
        setUserDetails({});
      }
    };

    fetchUserDetails();
    // console.log(fetchUserDetails());
  }, []);

  // Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // map marker
  const customIcon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/128/684/684908.png',
    iconSize: [38, 38],
  });

  // Searching for Device Information
  const handleSearch = async () => {
    if (!serial) {
      alert('Please enter a serial number');
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/hughes`,
        { serialNumber: serial },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setDevice(res.data.data[0]);

      setLat(res.data.data[0].latitudeActual);
      setLng(res.data.data[0].longitudeActual);
      setError(null);
      setSnackbarMessage(`Device Search Successful`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(`Error: ${error.message}`);
      setDevice(null);
    }
  };

  // Clearing Search Results
  const handleClear = () => {
    setSerial('');
    setDevice(null);
    setError(null);
    setPing(null);
    setCellularCheck(null);
    setCellularCheckPerformed(false);
    setLat('');
    setLng('');
    setSnackbarMessage('Device Search Cleared');
    setSnackbarOpen(true);
  };

  // Health Check
  const handleCellularCheck = async () => {
    try {
      const res = await axios.post(
        `http://localhost:8080/v1/engage/contactcenter/comm/hughes/ping`,
        { hostName: device.name },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setCellularCheck(res.data[0].results);
      setSignalStrength(res.data[0].results.signal_strength);
      setCellularCheckPerformed(true);
      setError(null);
      setSnackbarMessage(`Health Check Successful`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error Health Check on device:', error);
      setError(`Error: ${error.message}`);
      setCellularCheck(null);
    }
  };

  // Pinging Device
  const handlePing = async () => {
    try {
      const res = await axios.post(
        `http://localhost:8080/v1/engage/contactcenter/comm/hughes/ping`,
        { hostName: device.name },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // setPing(res.data[0].results);
      setError(null);
      setSnackbarMessage(`Ping Successful`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error pinging device:', error);
      setError(`Error: ${error.message}`);
      // setPing(null);
    }
  };

  // Reboot Device
  const handleReboot = () => {
    setDialogOpen(true);
  };

  const handleConfirmReboot = async () => {
    try {
      const rebootData = {
        locationId: null, // Replace with actual values
        hostname: `${device.name.toUpperCase()}`,
        serialNumber: `${device.diuSerialNum}`,
        device: 'Hughes',
        rebooted: 'YES', // or false based on your logic
        associateEmail: `${userDetails.email}`,
      };

      const res = await axios.post(
        // `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/reboot`,
        `http://localhost:8080/v1/engage/contactcenter/comm/reboot`,
        rebootData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log(`Device rebooted: ${serial}`);
      setDialogOpen(false);
      setSnackbarMessage(`Device Rebooted`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting reboot data:', error);
      // Handle error accordingly
    }
  };

  const handleCancelReboot = async () => {
    try {
      const rebootData = {
        locationId: null, // Replace with actual values
        hostname: `${device.name.toUpperCase()}`,
        serialNumber: `${device.diuSerialNum}`,
        device: 'Hughes',
        rebooted: 'NO', // or false based on your logic
        associateEmail: `${userDetails.email}`,
      };

      const res = await axios.post(
        // `${process.env.REACT_APP_ENDPOINT_QA_AGENTUTILS}/comm/reboot`,
        `http://localhost:8080/v1/engage/contactcenter/comm/reboot`,
        rebootData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // console.log(`Device Not Rebooted: ${serial}`);
      setDialogOpen(false);
      setSnackbarMessage(`Device Reboot Rejected`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting reboot data:', error);
      // Handle error accordingly
    }
  };

  return (
    <>
      <div className='container' style={{ display: 'flex' }}>
        <div style={{ flex: 1, padding: '10px' }}>
          <input
            type='text'
            value={serial}
            onChange={e => setSerial(e.target.value)}
            placeholder='Enter Hughes serial'
          />
          <button onClick={handleSearch}>Search</button>
          <button onClick={handleClear}>Clear</button>
          <br />
          <br />
          <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {device && (
              <Card
                variant='outlined'
                style={{ display: 'flex', width: '100%' }}
              >
                <CardContent style={{ flex: 1, display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    {cellularCheckPerformed && (
                      <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        Cellular Check:{' '}
                        {signalStrengthClean > 105 ? (
                          <span style={{ color: 'red', fontWeight: 'bold' }}>
                            BAD ({signalStrengthClean})
                          </span>
                        ) : signalStrengthClean < 105 &&
                          signalStrengthClean > 95 ? (
                          <span style={{ color: 'orange', fontWeight: 'bold' }}>
                            FAIR ({signalStrengthClean})
                          </span>
                        ) : signalStrengthClean <= 95 ? (
                          <span style={{ color: 'green', fontWeight: 'bold' }}>
                            GOOD ({signalStrengthClean})
                          </span>
                        ) : null}
                      </Typography>
                    )}
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Hostname:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.name.toUpperCase()}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Serial:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.diuSerialNum}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Model:{' '}
                      <span style={{ color: '#154db4' }}>{device.model}</span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Device Type:{' '}
                      <span style={{ color: '#154db4' }}>
                        {device.productGroup}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Location:
                    </Typography>
                    {/* <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      <span style={{ color: '#154db4' }}>Site Name</span>
                    </Typography> */}
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      <span style={{ color: '#154db4' }}>{device.address}</span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      <span style={{ color: '#154db4' }}>
                        {device.city}, {device.stateUSA} {device.zip}
                      </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Coordinates:{' '}
                      <span style={{ color: '#154db4' }}>
                        {lng}, {lat}
                      </span>
                    </Typography>
                    {/* <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Last Status Change:{' '}
                      <span style={{ color: '#154db4' }}>
                        N/A */}
                    {/* {moment
                          .unix(device.unit.time.lastStatusChange)
                          .format('dddd, MMMM Do, YYYY h:mm A')} */}
                    {/* </span>
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                      Last Power Status Update:{' '}
                      <span style={{ color: '#154db4' }}>
                        N/A */}
                    {/* {moment
                          .unix(device.unit.time.last_power_status_update)
                          .format('dddd, MMMM Do, YYYY h:mm A')} */}
                    {/* </span>
                    </Typography> */}
                    {cellularCheck && (
                      <>
                        <hr />
                        <p>Cellular Health Check Results:</p>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          Current State:{' '}
                          <span style={{ color: '#154db4' }}>
                            {cellularCheck.current_state.toUpperCase()}
                          </span>
                        </Typography>
                        {/* <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          Service Type:{' '}
                          <span style={{ color: '#154db4' }}>
                            {cellularCheck.service_type}
                          </span>
                        </Typography> */}
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          Uptime:{' '}
                          <span style={{ color: '#154db4' }}>
                            {cellularCheck.uptime}
                          </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          Location Updated:{' '}
                          <span style={{ color: '#154db4' }}>
                            {cellularCheck.location_updated}
                          </span>
                        </Typography>
                        {/* <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          WAN Interface 1:{' '}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            Name: {cellularCheck.wan_interfaces[0].name}
                          </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            State:{' '}
                            {cellularCheck.wan_interfaces[0].state.toUpperCase()}
                          </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            Carrier: {cellularCheck.wan_interfaces[0].carrier}
                          </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            Uptime: {cellularCheck.wan_interfaces[0].uptime}
                          </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          WAN Interface 2:{' '}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            Name: {cellularCheck.wan_interfaces[1].name}
                          </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            State:{' '}
                            {cellularCheck.wan_interfaces[1].state.toUpperCase()}
                          </span>
                        </Typography> */}
                        {/* <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            Carrier: {cellularCheck.wan_interfaces[1].carrier}
                          </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                          <span style={{ color: '#154db4' }}>
                            Uptime: {cellularCheck.wan_interfaces[1].uptime}
                          </span>
                        </Typography> */}
                      </>
                    )}
                  </div>
                  {/* {showMap && ( */}
                  <div
                    style={{
                      marginLeft: '20px',
                      width: '50%',
                      flexShrink: 0, // Prevent the map from shrinking
                    }}
                  >
                    <MapContainer
                      center={[lat, lng]}
                      zoom={17}
                      style={{ height: '50vh', width: '100%' }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      />
                      <Marker position={[lat, lng]} icon={customIcon}></Marker>
                    </MapContainer>
                  </div>
                  {/* )} */}
                </CardContent>
                {/* Action Buttons Sidepan */}
                <div
                  style={{
                    borderLeft: '1px solid #ccc',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {/* <Tooltip title='Show Map'>
                    <IconButton onClick={() => setShowMap(!showMap)}>
                      <GpsFixedIcon />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title='Ping Device'>
                    <IconButton onClick={() => handlePing()}>
                      <SensorsIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Cellular Health Check'>
                    <IconButton onClick={() => handleCellularCheck()}>
                      <HealthAndSafetyIcon />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title='Reboot Device'>
                    <IconButton onClick={() => handleReboot()}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip> */}
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        ContentProps={{
          style: {
            backgroundColor: '#4CAF50',
          },
        }}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleCancelReboot}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <WarningIcon sx={{ color: 'red' }} />
          {' Warning!'}
        </DialogTitle>
        <DialogContent>
          <Typography variant='body2'>
            Rebooting this device will disrupt service for the customer. Are you
            sure you want to do this?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelReboot} color='primary'>
            No
          </Button>
          <Button onClick={handleConfirmReboot} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HughesSearch;
