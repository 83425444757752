import { useNavigate } from "react-router-dom";

const useNavigateTo = () => {
  const navigate = useNavigate();

  return (path) => {
    navigate(path);
  };
};

export default useNavigateTo;
