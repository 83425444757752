import React, { useContext } from 'react';
import { CloudDownloadIcon, DeleteForeverIcon, ContentCopyIcon } from "../assets/icons/icons";
import { Box, Tooltip, IconButton } from "@mui/material";
import { LanguageContext } from '../context/languageContext';

// NRC
export const retailerFilesDataGrid = (translations) => ({
    apiEndpoint: 'https://gnie-useast-api-service.azurewebsites.net/v1/filemanagement/us/cc/retailer/files',
    columns: [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'articleID', headerName: translations.articleID || 'Article ID', flex: 1 },
        { field: 'fileName', headerName: translations.fileName || 'File Name', flex: 2 },
        { field: 'fileType', headerName: translations.fileType || 'File Type', flex: 1 },
        {
            field: 'functions',
            headerName: translations.actions || 'Actions',
            flex: 1,
            renderCell: params => <FunctionsCell {...params} />,
        },
        { field: 'createdBy', headerName: translations.createdBy || 'Created By', flex: 1 },
    ]
});

// FST
export const fstFilesDataGrid = (translations) => ({
    apiEndpoint: 'https://gnie-useast-api-service.azurewebsites.net/v1/filemanagement/us/fst/files',
    columns: [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'articleID', headerName: translations.articleID || 'Article ID', flex: 1 },
        { field: 'fileName', headerName: translations.fileName || 'File Name', flex: 2 },
        { field: 'fileType', headerName: translations.fileType || 'File Type', flex: 1 },
        {
            field: 'actions',
            headerName: translations.actions || 'Actions',
            flex: 1,
            renderCell: params => (
                <Box display='flex' alignItems='center'>
                    <Tooltip title={translations.copyFileLink || 'Copy File Link'}>
                        <IconButton onClick={() => (params.row)} size='small'>
                            <ContentCopyIcon sx={{ color: '#ff671f', fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.downloadFile || 'Download File'}>
                        <IconButton onClick={() => (params.row)} size='small'>
                            <CloudDownloadIcon sx={{ color: '#0c51a1', fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.deleteFile || 'Delete File'}>
                        <IconButton onClick={() => (params.row)} size='small' color='error'>
                            <DeleteForeverIcon sx={{ fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        { field: 'createdBy', headerName: translations.createdBy || 'Created By', flex: 1 },
    ]
});

// GCS
export const gcsFilesDataGrid = (translations) => ({
    apiEndpoint: 'https://gnie-useast-api-service.azurewebsites.net/v1/filemanagement/us/gcs/files',
    columns: [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'articleID', headerName: translations.articleID || 'Article ID', flex: 1 },
        { field: 'fileName', headerName: translations.fileName || 'File Name', flex: 2 },
        { field: 'fileType', headerName: translations.fileType || 'File Type', flex: 1 },
        {
            field: 'actions',
            headerName: translations.actions || 'Actions',
            flex: 1,
            renderCell: params => (
                <Box display='flex' alignItems='center'>
                    <Tooltip title={translations.copyFileLink || 'Copy File Link'}>
                        <IconButton onClick={() => (params.row)} size='small'>
                            <ContentCopyIcon sx={{ color: '#ff671f', fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.downloadFile || 'Download File'}>
                        <IconButton onClick={() => (params.row)} size='small'>
                            <CloudDownloadIcon sx={{ color: '#0c51a1', fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.deleteFile || 'Delete File'}>
                        <IconButton onClick={() => (params.row)} size='small' color='error'>
                            <DeleteForeverIcon sx={{ fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        { field: 'createdBy', headerName: translations.createdBy || 'Created By', flex: 1 },
    ]
});

// Costa Rica
export const crccFilesDataGrid = (translations) => ({
    apiEndpoint: 'https://gnie-useast-api-service.azurewebsites.net/v1/filemanagement/cr/cc/files',
    columns: [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'articleID', headerName: translations.articleID || 'Article ID', flex: 1 },
        { field: 'fileName', headerName: translations.fileName || 'File Name', flex: 2 },
        { field: 'fileType', headerName: translations.fileType || 'File Type', flex: 1 },
        {
            field: 'actions',
            headerName: translations.actions || 'Actions',
            flex: 1,
            renderCell: params => (
                <Box display='flex' alignItems='center'>
                    <Tooltip title={translations.copyFileLink || 'Copy File Link'}>
                        <IconButton onClick={() => (params.row)} size='small'>
                            <ContentCopyIcon sx={{ color: '#ff671f', fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.downloadFile || 'Download File'}>
                        <IconButton onClick={() => (params.row)} size='small'>
                            <CloudDownloadIcon sx={{ color: '#0c51a1', fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.deleteFile || 'Delete File'}>
                        <IconButton onClick={() => (params.row)} size='small' color='error'>
                            <DeleteForeverIcon sx={{ fontSize: '30px' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        { field: 'createdBy', headerName: translations.createdBy || 'Created By', flex: 1 },
    ]
});


// FunctionsCell component to use translations from context
export const FunctionsCell = (params) => {
  const translations = useContext(LanguageContext);
  return (
      <Box display='flex' alignItems='center'>
          <Tooltip title={translations.copyFileLink || 'Copy File Link'}>
              <IconButton onClick={() => (params.row)} size='small'>
                  <ContentCopyIcon sx={{ color: '#ff671f', fontSize: '30px' }} />
              </IconButton>
          </Tooltip>
          <Tooltip title={translations.downloadFile || 'Download File'}>
              <IconButton onClick={() => (params.row)} size='small'>
                  <CloudDownloadIcon sx={{ color: '#0c51a1', fontSize: '30px' }} />
              </IconButton>
          </Tooltip>
          <Tooltip title={translations.deleteFile || 'Delete File'}>
              <IconButton onClick={() => (params.row)} size='small' color='error'>
                  <DeleteForeverIcon sx={{ fontSize: '30px' }} />
              </IconButton>
          </Tooltip>
      </Box>
  );
}