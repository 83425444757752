import VentusSearch from '../components/comm/ventusSearch';
import HughesSearch from '../components/comm/hughesSearch';

export const utilities = [
  {
    tab: 'Ventus',
    items: [
      {
        title: 'Ventus',
        description: 'Ventus Devices',
        component: <VentusSearch />,
      },
    ],
  },
  {
    tab: 'Hughes',
    items: [
      {
        title: 'Hughes',
        description: 'Hughes',
        component: <HughesSearch />,
      },
    ],
  },
];
