import { useLocation } from "react-router-dom";

const useActivePage = () => {
  const location = useLocation();
  const isActive = (pathname) => location.pathname.startsWith(pathname);

  return { isActive };
};


export default useActivePage;
