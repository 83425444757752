import { gamingFailureModes, lotteryFailureModes, gamingLocations,
  gamingPartNumbers, jids, pcbItemNumbers,
  powerSupplyItemNumbers, gamingProducts, lotteryProducts,
  quadBursterNumberItems, readerItemNumbers, solutionsGaming,
  solutions, status, billAcceptorNumberItems, peripherals,
  terminals, printers, commDevice, fstLotteryProducts,
  displayMonitor, incorrectStatus, actionTaken, secondTimeReturn,
  reasons
} from './formDataConfigs';
import { LanguageContext } from '../context/languageContext';

// RRF Form Configs

export const lotteryRepairFormConfig = (translations) => ({
apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/rrf/lottery/repair/worklog/all`,
fields: [
  { name: 'startDate', label: translations.startDate || 'Start Date', type: 'date', required: true },
  { name: 'completionDate', label: translations.completionDate || 'Completion Date', type: 'date', required: true },
  { name: 'emailAddress', label: translations.emailAddress || 'Email Address', type: 'text', required: true },
  { name: 'rma', label: translations.rmaNumber || 'RMA Number', type: 'text' },
  { name: 'assetTag', label: translations.assetTag || 'Asset Tag', type: 'text' },
  { name: 'serialNumber', label: translations.serialNumber || 'Serial Number', type: 'text' },
  { name: 'failureMode', label: translations.failureMode || 'Failure Mode', type: 'select', options: lotteryFailureModes },
  { name: 'solution', label: translations.solution1 || 'Solution #1', type: 'select', options: solutions },
  { name: 'secondSolution', label: translations.solution2 || 'Solution #2', type: 'select', options: solutions },
  { name: 'partsUsed', label: translations.partsUsed || 'Parts Used', type: 'text' },
  { name: 'status', label: translations.status || 'Status', type: 'select', options: status },
  { name: 'workTime', label: translations.totalWorkTime || 'Total Work Time (minutes)', type: 'text' },
  { name: 'isSecondTimeReturn', label: translations.isSecondTimeReturn || 'Is Second Time Return', type: 'select', options: secondTimeReturn },
  { name: 'returnDate', label: translations.returnDate || 'Return Date', type: 'date' },
  { name: 'jurisdiction', label: translations.jurisdiction || 'Jurisdiction', type: 'select', options: jids },
  { name: 'product', label: translations.product || 'Product', type: 'select', options: lotteryProducts },
  { name: 'reader', label: translations.readerItemNumber || 'Reader Item Number', type: 'select', options: readerItemNumbers },
  { name: 'powerSupply', label: translations.powerSupplyItemNumber || 'Power Supply Item Number', type: 'select', options: powerSupplyItemNumbers },
  { name: 'pcb', label: translations.pcbItemNumber || 'PCB Item Number', type: 'select', options: pcbItemNumbers },
  { name: 'billAcceptor', label: translations.billAcceptorItemNumber || 'Bill Acceptor Item Number', type: 'select', options: billAcceptorNumberItems },
  { name: 'burster', label: translations.bursterItemNumber || 'Mini/Quad Burster Item Number', type: 'select', options: quadBursterNumberItems },
  { name: 'comments', label: translations.comments || 'Comments', type: 'text', multiline: true, rows: 4 },
],
});

export const gamingRepairFormConfig = (translations) => ({
apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/rrf/gaming/repair/worklog/all`,
fields: [
  { name: 'startDate', label: translations.startDate || 'Start Date', type: 'date', required: true },
  { name: 'completionDate', label: translations.completionDate || 'Completion Date', type: 'date', required: true },
  { name: 'emailAddress', label: translations.emailAddress || 'Email Address', type: 'text', required: true },
  { name: 'rmaNumber', label: translations.rmaNumber || 'RMA Number', type: 'text' },
  { name: 'assetTag', label: translations.assetTag || 'Asset Tag', type: 'text' },
  { name: 'serialNumber', label: translations.serialNumber || 'Serial Number', type: 'text' },
  { name: 'failureMode', label: translations.failureMode || 'Failure Mode', type: 'select', options: gamingFailureModes },
  { name: 'solution', label: translations.solution1 || 'Solution', type: 'select', options: solutionsGaming },
  { name: 'secondSolution', label: translations.solution2 || 'Solution #2', type: 'select', options: solutionsGaming },
  { name: 'partsUsed', label: translations.partsUsed || 'Parts Used', type: 'text' },
  { name: 'status', label: translations.status || 'Status', type: 'select', options: status },
  { name: 'workTime', label: translations.totalWorkTime || 'Total Work Time', type: 'text', helperText: translations.inMinutes || 'In minutes' },
  { name: 'gamingLocation', label: translations.location || 'Location', type: 'select', options: gamingLocations },
  { name: 'stoNumber', label: translations.stoNumber || 'STO Number', type: 'text' },
  { name: 'quantityRepaired', label: translations.quantityRepaired || 'Quantity Repaired', type: 'text' },
  { name: 'itemNumber', label: translations.itemNumber || 'Item Number', type: 'select', options: gamingPartNumbers },
  { name: 'product', label: translations.products || 'Products', type: 'select', options: gamingProducts },
  { name: 'comments', label: translations.comments || 'Comments', type: 'text', multiline: true, rows: 4 },
],
});

// FST Form Configs

export const incorrectCaseFormConfig = (translations) => ({
apiEndpoint: `${process.env.REACT_APP_ENDPOINT_INCORRECT_CASE_FORM}`,
fields: [
  { name: 'jurisdiction', label: translations.jurisdiction || 'Jurisdiction', type: 'select', options: jids, required: true },
  { name: 'caseNumber', label: translations.caseNumber || 'Case Number', type: 'text' },
  { name: 'incorrectStatus', label: translations.incorrectStatus || 'Description of Incorrect Status', type: 'select', options: incorrectStatus, required: true },
  { name: 'fstNumber', label: translations.fstNumber || 'FST Number', type: 'text' },
  { name: 'actionTaken', label: translations.actionTaken || 'Action Taken', type: 'select', options: actionTaken },
],
});

export const fstOvertimeFormConfig = {
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_OT_FORM}`,
  method: 'POST',
  fields: [
    {name: 'isTech', label: 'Are you a Tech?', type: 'radio', options: [
      {value: 'yes', label: 'Yes'},
      {value: 'no', label: 'No'}
    ],
    required: true},
    {
      name: 'penalty',
      label: 'Case Going to Penalty',
      type: 'radio',
      options: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
    },
    {name: 'openCase', label: 'Open Case', type: 'radio', options: [
      {value: 'yes', label: 'Yes'},
      {value: 'no', label: 'No'}
    ],
    required: true},
    {name: 'jurisdiction', label: 'Jurisdiction', type: 'select', options: jids, required: true,},
    // {name: 'userId', label: 'User ID', type: 'text', required: true},
    // { name: 'email', label: 'Email', type: 'text' },
    { name: 'caseNumber', label: 'Case Number', type: 'text' },
    { name: 'fstNumber', label: 'FST Number', type: 'text', required: true },
    { name: 'dateWorked', label: 'Date Worked', type: 'date', required: true },
    {
      name: 'overtimeLength',
      label: 'Overtime Length',
      type: 'text',
      required: true,
      inputType: 'number',
      step: '0.01',
    },
    {
      name: 'reason',
      label: 'Reason',
      type: 'select',
      options: reasons,
      required: true,
    },
    {
      name: 'comments',
      label: 'Comments',
      type: 'text',
      multiline: true,
      rows: 4,
    },
  ],
};


export const fieldRepairFormConfig = (translations) => ({
apiEndpoint: `${process.env.REACT_APP_ENDPOINT_REPAIR_FORM }`,
fields: [
  { name: 'startDate', label: translations.startDate || 'Start Date', type: 'date', required: true },
  { name: 'completionDate', label: translations.completionDate || 'Completion Date', type: 'date', required: true },
  { name: 'emailAddress', label: translations.emailAddress || 'Email Address', type: 'text', required: true },
  { name: 'rma', label: translations.rmaNumber || 'RMA Number', type: 'text' },
  {name: 'assetTag', label: translations.assetTag || 'Asset Tag', type: 'text'},
  { name: 'serialNumber', label: translations.serialNumber || 'Serial Number', type: 'text' },
  { name: 'caseNumber', label: translations.caseNumber || 'Case Number', type: 'text' },
  { name: 'failureMode', label: translations.failureMode || 'Failure Mode', type: 'select', options: lotteryFailureModes },
  { name: 'solution', label: translations.solution1 || 'Solution #1', type: 'select', options: solutions },
  { name: 'secondSolution', label: translations.solution2 || 'Solution #2', type: 'select', options: solutions },
  { name: 'partsUsed', label: translations.partsUsed || 'Parts Used', type: 'text' },
  { name: 'commDevice', label: translations.commDevice || 'Comm Device Item Number', type: 'select', options: commDevice },
  { name: 'terminal', label: translations.terminal || 'Terminal Item Number', type: 'select', options: terminals },
  { name: 'printers', label: translations.printer || 'Printer Item Number', type: 'select', options: printers },
  { name: 'peripherals', label: translations.peripherals || 'Peripherals Item Number', type: 'select', options: peripherals },
  { name: 'displayMonitors', label: translations.displayMonitors || 'Display/Monitors Item Number', type: 'select', options: displayMonitor },
  { name: 'status', label: translations.status || 'Status', type: 'select', options: status },
  { name: 'workTime', label: translations.totalWorkTime || 'Total Work Time (minutes)', type: 'text' },
  { name: 'isSecondTimeReturn', label: translations.isSecondTimeReturn || 'Is Second Time Return', type: 'text' },
  { name: 'returnDate', label: translations.returnDate || 'Return Date', type: 'date' },
  { name: 'jurisdiction', label: translations.jurisdiction || 'Jurisdiction', type: 'select', options: jids },
  { name: 'product', label: translations.product || 'Product', type: 'select', options: fstLotteryProducts },
  { name: 'reader', label: translations.readerItemNumber || 'Reader Item Number', type: 'select', options: readerItemNumbers },
  { name: 'powerSupply', label: translations.powerSupplyItemNumber || 'Power Supply Item Number', type: 'select', options: powerSupplyItemNumbers },
  { name: 'pcb', label: translations.pcbItemNumber || 'PCB Item Number', type: 'select', options: pcbItemNumbers },
  { name: 'billAcceptor', label: translations.billAcceptorItemNumber || 'Bill Acceptor Item Number', type: 'select', options: billAcceptorNumberItems },
  { name: 'burster', label: translations.bursterItemNumber || 'Mini/Quad Burster Item Number', type: 'select', options: quadBursterNumberItems },
  { name: 'comments', label: translations.comments || 'Comments', type: 'text', multiline: true, rows: 4 },
  ],
  });