import React, {useContext} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  Box
} from '@mui/material';
import { LanguageContext } from '../context/languageContext';

const FileInfoModal = ({ open, onClose, file }) => {

  const translations = React.useContext(LanguageContext);

  if (!file) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{translations.fileInformation || 'File Information'}</DialogTitle>
      <DialogContent dividers>
        <List>
          <ListItem>
            <ListItemText
              primary={translations.fileName || 'File Name'}
              secondary={file.fileName}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.fileType || 'File Type'}
              secondary={file.fileType}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.createdBy || 'Created By'}
              secondary={file.createdBy}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.lastModified || 'Last Modified'}
              secondary={new Date(file.lastModified).toLocaleString()}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.link || 'Link'}
              secondary={
                <Link href={file.link} target="_blank" rel="noopener noreferrer">
                  {file.link}
                </Link>
              }
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={translations.size || 'Size'}
              secondary={file.size}
              primaryTypographyProps={{ fontWeight: 'bold' }}
            />
          </ListItem>
          {file.metadata && Object.keys(file.metadata).length > 0 && (
            <>
              <Divider />
              {/* <ListItem>
                <ListItemText
                  primary="Metadata (Tags)"
                  primaryTypographyProps={{ fontWeight: 'bold' }}
                />
              </ListItem> */}
              <Box ml={2}>
                {Object.entries(file.metadata).map(([key, value]) => (
                  <ListItem key={key} disableGutters>
                    <ListItemText
                      primary={key}
                      secondary={value}
                      primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                  </ListItem>
                ))}
              </Box>
            </>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">{translations.close || 'Close'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileInfoModal;
