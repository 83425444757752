import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

// Define AAD group to accessible paths mapping
const groupPathAccess = {
  engage_administrators: ["/forms", "/home", "/login", "/reports", "/contact-center", "/rss/links", "/files"],
  engage_us_cc: ["/forms", "/home", "/login", "/contact-center", "/rss/links", "/files"],
  engage_us_fst: ["/forms", "/home", "/login", "/files"],
  engage_us_report: ["/home", "/login", "/reports", "/files"],
  engage_us_inv: ["/home", "/login", "/reports", "/forms", "/files"],
  engage_us_rlt: ["/home", "/login", "/forms", "/files"],
};


// Create authentication context
const AuthContext = createContext();

// Custom hook to use authentication context
export const useAuth = () => useContext(AuthContext);

// Authentication provider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = Cookies.get("token");
    return !!token;
  });
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [userId, setUserId] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  const [accessiblePaths, setAccessiblePaths] = useState([]);

  const getToken = () => Cookies.get("token");

  const login = (userDetails) => {
    Cookies.set("token", userDetails.token);
    setUserId(userDetails.userId);
    setIsAuthenticated(true);
  
    // Normalize group names to lowercase and filter
    const filteredGroups = userDetails.groups.filter(group => group.toLowerCase().startsWith('engage_'));
  
    const normalizedGroupPathAccess = Object.keys(groupPathAccess).reduce(
      (acc, key) => {
        acc[key.toLowerCase()] = groupPathAccess[key];
        return acc;
      },
      {}
    );
  
    const userPaths = filteredGroups
      .flatMap((group) => normalizedGroupPathAccess[group.toLowerCase()] || [])
      .filter((value, index, self) => self.indexOf(value) === index);
      
    setUserGroups(filteredGroups);
    setAccessiblePaths(userPaths);
  
    localStorage.setItem("userGroups", JSON.stringify(filteredGroups));
  };
  
  const logout = () => {
    Cookies.remove("token");
    setUserId("");
    setIsAuthenticated(false);
    setUserGroups([]);
    setAccessiblePaths([]);
    localStorage.removeItem("userGroups"); // Clear persisted user groups on logout
  };

  useEffect(() => {
    const storedGroups = JSON.parse(localStorage.getItem("userGroups") || "[]").filter(group => group.toLowerCase().startsWith('engage_')); 
  
    if (storedGroups.length > 0) {
      setUserGroups(storedGroups);
      const normalizedGroupPathAccess = Object.keys(groupPathAccess).reduce(
        (acc, key) => {
          acc[key.toLowerCase()] = groupPathAccess[key];
          return acc;
        },
        {}
      );
  
      const paths = storedGroups
        .flatMap((group) => normalizedGroupPathAccess[group.toLowerCase()] || [])
        .filter((value, index, self) => self.indexOf(value) === index);
  
      setAccessiblePaths(paths);
    } else {
      setAccessiblePaths([]);
    }
  
    setIsAuthenticated(!!Cookies.get("token"));
    setLoading(false); 
  }, []);
  
if (loading) {
  return <div>Loading...</div>; // Ensure app waits for the loading to complete
}


  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userId,
        getToken,
        login,
        logout,
        userGroups,
        accessiblePaths,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
