import React from 'react';
import {
  EditIcon,
  CheckCircleOutlineIcon,
  HighlightOffIcon,
  PendingIcon,
} from '../assets/icons/icons';
import { Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { LanguageContext } from '../context/languageContext';

// RRF Report Configs
export const gamingRepairWorklogReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/rrf/gaming/repair/worklog/all`,
  columns: [
    {
      field: 'startDate',
      headerName: translations.startDate || 'Start Date',
      width: 200,
    },
    {
      field: 'completionDate',
      headerName: translations.completionDate || 'Completion Date',
      width: 200,
    },
    { field: 'name', headerName: translations.name || 'Name', width: 200 },
    {
      field: 'rma',
      headerName: translations.rmaNumber || 'RMA Number',
      width: 200,
    },
    {
      field: 'assetTag',
      headerName: translations.assetTag || 'Asset Tag',
      width: 200,
    },
    {
      field: 'serialNumber',
      headerName: translations.serialNumber || 'Serial Number',
      width: 200,
    },
    {
      field: 'failureMode',
      headerName: translations.failureMode || 'Failure Mode',
      width: 200,
    },
    {
      field: 'solution',
      headerName: translations.solution || 'Solution',
      width: 200,
    },
    {
      field: 'secondSolution',
      headerName: translations.secondSolution || 'Second Solution',
      width: 200,
    },
    {
      field: 'partsUsed',
      headerName: translations.partsUsed || 'Parts Used',
      width: 200,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 200,
    },
    {
      field: 'workTime',
      headerName: translations.totalWorkTime || 'Total Work Time (minutes)',
      width: 200,
    },
    {
      field: 'isSecondTimeReturn',
      headerName: translations.secondReturn || 'Second Return',
      width: 200,
    },
    {
      field: 'returnDate',
      headerName: translations.returnDate || 'Return Date',
      width: 200,
    },
    {
      field: 'gamingLocation',
      headerName: translations.location || 'Location',
      width: 200,
    },
    {
      field: 'stoNumber',
      headerName: translations.stoNumber || 'STO Number',
      width: 200,
    },
    {
      field: 'itemNumber',
      headerName: translations.itemNumber || 'Item Number',
      width: 200,
    },
    {
      field: 'products',
      headerName: translations.products || 'Products',
      width: 200,
    },
    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 400,
    },
  ],
});

export const lotteryRepairWorklogReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/rrf/lottery/repair/worklog/all`,
  columns: [
    {
      field: 'Action',
      headerName: translations.action || 'Action',
      width: 100,
      renderCell: params => <ActionCell {...params} />,
    },
    {
      field: 'date',
      headerName: translations.startDate || 'Start Date',
      width: 200,
    },
    {
      field: 'completionDate',
      headerName: translations.completionDate || 'Completion Date',
      width: 200,
    },
    { field: 'name', headerName: translations.name || 'Name', width: 200 },
    {
      field: 'rma',
      headerName: translations.rmaNumber || 'RMA Number',
      width: 200,
      editable: true,
    },
    {
      field: 'assetTag',
      headerName: translations.assetTag || 'Asset Tag',
      width: 200,
      editable: true,
    },
    {
      field: 'serialNumber',
      headerName: translations.serialNumber || 'Serial Number',
      width: 200,
      editable: true,
    },
    {
      field: 'failureMode',
      headerName: translations.failureMode || 'Failure Mode',
      width: 200,
    },
    {
      field: 'solution',
      headerName: translations.solution || 'Solution',
      width: 200,
    },
    {
      field: 'secondSolution',
      headerName: translations.secondSolution || 'Second Solution',
      width: 200,
    },
    {
      field: 'partsUsed',
      headerName: translations.partsUsed || 'Parts Used',
      width: 200,
      editable: true,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 200,
    },
    {
      field: 'workTime',
      headerName: translations.totalWorkTime || 'Total Work Time (minutes)',
      width: 200,
    },
    {
      field: 'isSecondTimeReturn',
      headerName: translations.secondReturn || 'Second Return',
      width: 200,
    },
    {
      field: 'returnDate',
      headerName: translations.returnDate || 'Return Date',
      width: 200,
    },
    {
      field: 'jurisdiction',
      headerName: translations.jurisdiction || 'Jurisdiction',
      width: 200,
    },
    {
      field: 'product',
      headerName: translations.product || 'Product',
      width: 200,
    },
    {
      field: 'reader',
      headerName: translations.readerItemNumber || 'Reader Item Number',
      width: 200,
    },
    {
      field: 'powerSupply',
      headerName:
        translations.powerSupplyItemNumber || 'Power Supply Item Number',
      width: 200,
    },
    {
      field: 'pcb',
      headerName: translations.pcbItemNumber || 'PCB Supply Item Number',
      width: 200,
    },
    {
      field: 'billAcceptor',
      headerName:
        translations.billAcceptorItemNumber || 'Bill Acceptor Item Number',
      width: 200,
    },
    {
      field: 'burster',
      headerName:
        translations.bursterItemNumber || 'Mini/Quad Burster Item Number',
      width: 200,
    },
    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 400,
      editable: true,
    },
  ],
});

// FST Report Configs
export const fstOvertimeReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/fst/schedule/ot/`,
  columns: [
    {
      field: 'Action',
      headerName: translations.action || 'Action',
      width: 100,
      renderCell: params => <ActionCell {...params} />,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 150,
      renderCell: params => {
        const { value } = params;
        const statusStyles = {
          APPROVED: {
            color: 'green',
            icon: (
              <CheckCircleOutlineIcon
                sx={{ marginLeft: 1, fontSize: 16, color: 'green' }}
              />
            ),
            label: translations.approved || 'Approved',
          },
          REJECTED: {
            color: 'red',
            icon: (
              <HighlightOffIcon
                sx={{ marginLeft: 1, fontSize: 16, color: 'red' }}
              />
            ),
            label: translations.rejected || 'Rejected',
          },
          PENDING: {
            color: '#ffc72c',
            icon: (
              <PendingIcon
                sx={{ marginLeft: 1, fontSize: 16, color: '#ffc72c' }}
              />
            ),
            label: translations.pending || 'Pending',
          },
        };
        const currentStatusStyle = statusStyles[value] || {
          color: 'inherit',
          icon: null,
        };
        return (
          <Typography
            variant='body2'
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 2,
              fontSize: 16,
              padding: '4px',
              borderRadius: '4px',
              border: `1px solid ${currentStatusStyle.color}`,
              color: currentStatusStyle.color,
            }}
          >
            {value}
            {currentStatusStyle.icon}
          </Typography>
        );
      },
    },
    {
      field: 'date',
      headerName: translations.date || 'Date',
      width: 200,
      editable: false,
    },
    {
      field: 'jurisdiction',
      headerName: translations.jurisdiction || 'Jurisdiction',
      width: 150,
      editable: false,
    },
    {
      field: 'fstNumber',
      headerName: translations.fstNumber || 'FST Number',
      width: 150,
      editable: false,
    },
    {
      field: 'emailAddress',
      headerName: translations.emailAddress || 'Email Address',
      width: 200,
      editable: false,
    },
    {
      field: 'displayName',
      headerName: translations.displayName || 'Display Name',
      width: 200,
      editable: false,
    },
    {
      field: 'manager',
      headerName: translations.manager || 'Manager',
      width: 150,
      editable: false,
    },
    {
      field: 'dateWorked',
      headerName: translations.dateWorked || 'Date Worked',
      width: 150,
      editable: false,
    },
    {
      field: 'overtimeLength',
      headerName: translations.overtimeLength || 'Overtime Length',
      width: 150,
      editable: true,
    },
    {
      field: 'penalty',
      headerName: translations.penalty || 'Penalty',
      width: 100,
      editable: false,
    },
    {
      field: 'reason',
      headerName: translations.reason || 'Reason',
      width: 150,
      editable: true,
    },
    {
      field: 'caseNumber',
      headerName: translations.caseNumber || 'Case Number',
      width: 150,
      editable: false,
    },
    {
      field: 'retailerId',
      headerName: translations.retailerId || 'Retailer ID',
      width: 150,
      editable: false,
    },
    {
      field: 'storeName',
      headerName: translations.storeName || 'Store Name',
      width: 150,
      editable: false,
    },
    {
      field: 'address',
      headerName: translations.address || 'Address',
      width: 150,
      editable: false,
    },
    {
      field: 'phoneNumber',
      headerName: translations.phoneNumber || 'Phone Number',
      width: 150,
      editable: false,
    },
    {
      field: 'caseReason',
      headerName: translations.caseReason || 'Case Reason',
      width: 150,
      editable: false,
    },
    {
      field: 'caseStatus',
      headerName: translations.caseStatus || 'Case Status',
      width: 150,
      editable: false,
    },
    {
      field: 'approvedBy',
      headerName: translations.approver || 'Approver',
      width: 150,
      editable: false,
    },
    {
      field: 'dateApproved',
      headerName: translations.approvalDate || 'Approval Date',
      width: 150,
      editable: false,
    },
    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 150,
      editable: true,
    },
  ],
});

export const incorrectCaseReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/fst/incorrectcase/all`,
  columns: [
    { field: 'date', headerName: translations.date || 'Date', width: 150 },
    {
      field: 'jurisdiction',
      headerName: translations.jurisdiction || 'Jurisdiction',
      width: 150,
    },
    {
      field: 'fstNumber',
      headerName: translations.fstNumber || 'FST Number',
      width: 150,
    },
    {
      field: 'caseNumber',
      headerName: translations.caseNumber || 'Case Number',
      width: 200,
      editable: false,
    },
    {
      field: 'retailerId',
      headerName: translations.retailerId || 'Retailer ID',
      width: 200,
      editable: false,
    },
    {
      field: 'storeName',
      headerName: translations.storeName || 'Store Name',
      width: 150,
      editable: false,
    },
    {
      field: 'storeAddress',
      headerName: translations.storeAddress || 'Store Address',
      width: 150,
    },
    {
      field: 'closeDate',
      headerName: translations.closeDate || 'Close Date',
      width: 150,
    },
    {
      field: 'incorrectStatus',
      headerName: translations.incorrectStatus || 'Incorrect Status',
      width: 100,
    },
    {
      field: 'actionTaken',
      headerName: translations.actionTaken || 'Action Taken',
      width: 150,
    },
    { field: 'email', headerName: translations.email || 'Email', width: 150 },
    {
      field: 'displayName',
      headerName: translations.displayName || 'Display Name',
      width: 150,
    },
    {
      field: 'supervisor',
      headerName: translations.supervisor || 'Supervisor',
      width: 150,
      editable: false,
    },
    {
      field: 'createdBy',
      headerName: translations.createdBy || 'Created By',
      width: 150,
      editable: false,
    },
  ],
});

export const fieldRepairReport = translations => ({
  apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/rrf/field/repair/worklog/all`,
  columns: [
    {
      field: 'date',
      headerName: translations.startDate || 'Start Date',
      width: 200,
    },
    {
      field: 'completionDate',
      headerName: translations.completionDate || 'Completion Date',
      width: 200,
    },
    {
      field: 'displayName',
      headerName: translations.name || 'Name',
      width: 200,
    },
    {
      field: 'assetTag',
      headerName: translations.assetTag || 'Asset Tag',
      width: 200,
    },
    {
      field: 'serialNumber',
      headerName: translations.serialNumber || 'Serial Number',
      width: 200,
    },
    {
      field: 'caseNumber',
      headerName: translations.caseNumber || 'Case Number',
      width: 200,
    },
    {
      field: 'failureMode',
      headerName: translations.failureMode || 'Failure Mode',
      width: 200,
    },
    {
      field: 'solution',
      headerName: translations.solution || 'Solution',
      width: 200,
    },
    {
      field: 'secondSolution',
      headerName: translations.secondSolution || 'Second Solution',
      width: 200,
    },
    {
      field: 'partsUsed',
      headerName: translations.partsUsed || 'Parts Used',
      width: 200,
    },
    {
      field: 'commDevice',
      headerName: translations.commDevice || 'Comm Device',
      width: 200,
    },
    {
      field: 'terminal',
      headerName: translations.terminal || 'Terminal',
      width: 200,
    },
    {
      field: 'printers',
      headerName: translations.printer || 'Printer',
      width: 200,
    },
    {
      field: 'peripherals',
      headerName: translations.peripherals || 'Peripherals',
      width: 200,
    },
    {
      field: 'displayMonitors',
      headerName: translations.displayMonitor || 'Display/Monitor',
      width: 200,
    },
    {
      field: 'status',
      headerName: translations.status || 'Status',
      width: 200,
    },
    {
      field: 'workTime',
      headerName: translations.totalWorkTime || 'Total Work Time (minutes)',
      width: 200,
    },
    {
      field: 'isSecondReturn',
      headerName: translations.secondReturn || 'Second Return',
      width: 200,
    },
    {
      field: 'returnDate',
      headerName: translations.returnDate || 'Return Date',
      width: 200,
    },
    {
      field: 'jurisdiction',
      headerName: translations.jurisdiction || 'Jurisdiction',
      width: 200,
    },
    {
      field: 'product',
      headerName: translations.product || 'Product',
      width: 200,
    },
    {
      field: 'reader',
      headerName: translations.readerItemNumber || 'Reader Item Number',
      width: 200,
    },
    {
      field: 'powerSupply',
      headerName:
        translations.powerSupplyItemNumber || 'Power Supply Item Number',
      width: 200,
    },
    {
      field: 'pcb',
      headerName: translations.pcbItemNumber || 'PCB Supply Item Number',
      width: 200,
    },
    {
      field: 'billAcceptor',
      headerName:
        translations.billAcceptorItemNumber || 'Bill Acceptor Item Number',
      width: 200,
    },
    {
      field: 'burster',
      headerName:
        translations.bursterItemNumber || 'Mini/Quad Burster Item Number',
      width: 200,
    },
    {
      field: 'comments',
      headerName: translations.comments || 'Comments',
      width: 400,
    },
  ],
});

// NRC Comm Audit Logs
export const commAuditLog = {
  // apiEndpoint: `${process.env.REACT_APP_ENDPOINT_QA_REPORTING}/contactcenter/comm/reboot/log`,
  apiEndpoint: `http://localhost:8080/v1/engage/contactcenter/comm/reboot/log`,
  columns: [
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 200,
    },
    { field: 'locationId', headerName: 'Location ID', width: 200 },
    { field: 'hostname', headerName: 'Hostname', width: 200 },
    { field: 'serialNumber', headerName: 'Serial Number', width: 200 },
    { field: 'device', headerName: 'Device', width: 200 },
    { field: 'rebooted', headerName: 'Rebooted', width: 200 },
    { field: 'associateEmail', headerName: 'Associate', width: 200 },
  ],
};

export const ActionCell = ({ id, handleShow }) => (
  <GridActionsCellItem
    icon={<EditIcon sx={{ color: '#ffc72c' }} />}
    onClick={() => handleShow(id)}
  />
);
